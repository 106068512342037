import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function GetMultaCancelamento(idMatricula) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            recuperaMulta
        },
        errors
    } = await client.query({
        query: gql`
        query RecuperaMulta($idMatricula: String) {
          recuperaMulta(idMatricula: $idMatricula) {
            isencaoDaMultaSolicitada
            servicoPermiteIsencaoDaMulta
            valorMultaList {
              valor
              idServico
              template {
                id
              }
              responsavel {
                id
                nome
                cpf
                grauParentesco
                grauParentescoNome
                celular
                telefoneFixo
                email
                cep
                cidade
                bairro
                endereco
                numero
                complemento
                estado
                emancipado
                novaSenha
                senhaSite
                senha
                dataNascimento
                cadastroPendente
                urlImagemPerfil
                rg
                rateio
                codigoErp
                codigoCliente
                urlContrato
                responsavelPF
                tipoResponsavel
              }
            }
          }
        }   
        `,
        variables: {
            idMatricula
        }
    })

    dispatchGraphError(errors);

    return recuperaMulta;
}


export async function PostCriarCancelamento(request) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            criarCancelamento
        },
        errors
    } = await client.mutate({
        mutation: gql`
        mutation CriarCancelamento($criarCancelamentoRequest: CriarCancelamentoRequest) {
          criarCancelamento(criarCancelamentoRequest: $criarCancelamentoRequest) {
            value {
              linhaDigitavel
              boletoRegistrado
              msgBoletoRegistrado
              nossoNumero
              codigoBarra
              digitoNossoNumero
              dataTrans
              companyDocument
            }
            messages {
              text
              typeMessage
            }
            statusCode
          }
        }
        `,
        variables: {
            criarCancelamentoRequest: request
        }
    })

    dispatchGraphError(errors);

    return criarCancelamento;
}