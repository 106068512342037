import React from 'react';
import {Dropdown, DropdownButton} from "react-bootstrap";

/**
 * @param {[{id: string, label: string|JSX.Element, hidden?: boolean}]} options
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
function DropdownActionButton(
  {
    options = [],
    onClick,
  }
) {
  
  const handleOptionClick = option => event => {
    if (onClick) {
      onClick(event, option);
    }
  }
  
  return (
    <DropdownButton
      title="Ações"
      variant="light"
    >
      {options.map((option, index) =>
        <Dropdown.Item
          key={index}
          style={{
            display: option.hidden ? "none" : "block",
            paddingRight: "30px"
          }}
          onClick={handleOptionClick(option)}
        >
          {option.label}
        </Dropdown.Item>
      )}
    </DropdownButton>
  );
}

export default DropdownActionButton;