
export const fetchImageGet = async (endpoint, accessToken) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    try {
        const result = await fetch(endpoint, options);
        return await result.blob();
    } catch (error) {
        return console.log(error);
    }
}