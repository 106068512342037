import React from 'react';
import EditableBadge from "./EditableBadge";

function Column(
  {
    id = "",
    label = "",
    editable = false
  }
) {
  return (
    <th key={id}>
      {label} {editable && (
      <EditableBadge/>
    )}
    </th>
  )
}

export default Column;