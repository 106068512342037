import {v4 as uuidv4} from "uuid";

export default class FinancialStatement {
  id = uuidv4(); // Removido ao exportar
  InstallmentAmountCur = 1542.50;
  InstallmentNetAmount = 1542.50;
  PaymentMethod = "ITBol_MBB";
  TotalDiscount = 0.0;
  CashdiscDueDate = "0001-01-01T00:00:00";
  CashDiscValue = 0.0;
  DueDate = "2023-04-12T12:00:00";
  InvoiceId = "SEB-0468503 2/12";
  ItemId = "B000065";
  Installment = 2;
  ConfigId = "MENSALIDADE";
  Responsible = "SEBC-272593";
  TransDate = "2023-04-06T12:00:00";
  ReserveValue = 0.0;
  ScholarshipValue = 0.0;
  PaymMethodDiscAmount = 0.0;
  TransactionType = 1;
  TypeOfMovement = 1;
  MovementDate = "0001-01-01T00:00:00";
  MatriculaId = "SEB-0468503";
  PaymentStatus = 1;
  AmountPaid = 0.0;
  TypeOfPayment = 0;
  AgreementDocument = null;
  RefCustTrans = 0;
  RefCustTransOpen = 0;
  RefCustSettlement = 0;

  toJSON() {
    const json = {...this};
    delete json.id;
    return json;
  }
}