import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import * as _responsavelService from '../../service/responsavel-service';
import * as _grauParentescoService from '../../service/grauParentesco-service';
import * as _cepService from '../../service/cep-service';
import * as formValidacao from "../../Helpers/form-validacao.js";
import * as Message from '../../components/shared/Message';
import * as _util from '../../Helpers/utils.js';

import {
    NavDropdown,
    Nav,
} from 'react-bootstrap';
import csatList from "../../utils/csatList";

export class Movimentacoes_responsavel extends Component {
    static displayName = Movimentacoes_responsavel.name;

    constructor(props) {
        super(props);
        this.state = {
            exibeModal: false,
            isLoading: false,
            isLoadingCpf: false,
            isLoadingBtn: false,
            isTroca:false,
            errors: {},
            Matricula: this.props.Matricula,
            responsavel: {},
            novoResponsavel: {},
            trocarResponsavel: false,
            options: [],
            grauParentesco: "",
            nomeEstado: ""
        };
    }
    async componentDidMount() {
        this.setState({ isLoading: true });
        var options = await _grauParentescoService.ObterListagemGrauParentesco();
        var responsavel = null;
        if (this.props.ResponsavelPedagogico) {
            responsavel = await _responsavelService.GetResponsavelPedagogicoByMatricula(this.state.Matricula.idMatricula);
        } else {
            responsavel = await _responsavelService.GetResponsavelByMatricula(this.state.Matricula.idMatricula);
        }
        this.setState({ isLoading: false, responsavel: responsavel, options: options });
    }
    change(event) {
        
        var campos = this.state.novoResponsavel;
        campos[event.target.name] = event.target.value;

        if (event.target.name == "cpf") {
            this.OnChangeCpfNovoResponsavel(event.target.value);
        }

        if (event.target.name == "cep" && formValidacao.isValidCep(event.target.value)) {
            this.OnChangeCep(event.target.value);
        }

        this.setState({ novoResponsavel: campos });
    }
    async OnChangeCep(cep) {
        var cepRec = await _cepService.buscarCep(cep);
        var novoResponsavel = this.state.novoResponsavel;
        var nomeEstado = "";
        if (cepRec) {
            novoResponsavel.cidade = cepRec.localidade;
            novoResponsavel.estado = cepRec.uf;
            novoResponsavel.bairro = cepRec.bairro;
            novoResponsavel.endereco = cepRec.logradouro;
            nomeEstado = _util.BuscarNomeEstado(cepRec.uf);
        } else {
            novoResponsavel.cidade = "";
            novoResponsavel.estado = "";
            novoResponsavel.bairro = "";
            novoResponsavel.endereco = "";
        }
        this.setState({ novoResponsavel: novoResponsavel, nomeEstado: nomeEstado });
    }
    async OnChangeCpfNovoResponsavel(cpf) {
        if (formValidacao.isValidCPF(cpf)) {
            this.setState({ isLoadingCpf: true });
            var responsavelRecuperado = {};
            var nomeEstado = "";
            var resp = await _responsavelService.GetbyCpfResponsavel(cpf);
            if (responsavelRecuperado != null && responsavelRecuperado != undefined) {
                responsavelRecuperado = {
                    ...resp,
                    cpf: cpf,
                }
                nomeEstado = _util.BuscarNomeEstado(responsavelRecuperado.estado);
            }
            this.setState({ novoResponsavel: responsavelRecuperado, nomeEstado: nomeEstado, isLoadingCpf: false });
        }
    }
    async OnChangeTrocarResponsavel(value) {
        this.setState({ trocarResponsavel: value, novoResponsavel: {} });
    }
    formValidation() {
        const element = this.state.novoResponsavel;
        let errors = {};
        let formIsValid = true;

        if (!formValidacao.isValidCPF(element.cpf)) {
            formIsValid = false;
            errors[element + "_cpf"] = "Campo obrigatório ou inválido";
        } else {
            errors[element + "_cpf"] = undefined;
        }

        if (!formValidacao.isValidData(element.dataNascimento)) {
            formIsValid = false;
            errors[element + "_dataNascimento"] = "Campo obrigatório ou inválido";
        } else {
            errors[element + "_dataNascimento"] = undefined;
        }

        if (!formValidacao.isValidTexto(element.nome)) {
            formIsValid = false;
            errors[element + "_nome"] = "Campo obrigatório.";
        } else {
            errors[element + "_nome"] = undefined;
        }

        if (!formValidacao.isValidComboBox(element.grauParentesco)) {
            formIsValid = false;
            errors[element + "_grauParentesco"] = "Campo obrigatório.";
        } else {
            errors[element + "_grauParentesco"] = undefined;
        }

        if (!formValidacao.isValidCelular(element.celular)) {
            formIsValid = false;
            errors[element + "_celular"] = "Campo obrigatório ou inválido";
        } else {
            errors[element + "_celular"] = undefined;
        }

        if (formValidacao.isValidPreenchido(element.telefone)) {
            if (!formValidacao.isValidTelefone(element.telefone)) {
                formIsValid = false;
                errors[element + "_telefone"] = "Campo inválido";
            } else {
                errors[element + "_telefone"] = undefined;
            }

        } else {
            errors[element + "_telefone"] = undefined;
        }

        if (!formValidacao.isValidEmail(element.email)) {
            formIsValid = false;
            errors[element + "_email"] = "Campo obrigatório ou formato inválido";
        } else {
            errors[element + "_email"] = undefined;
        }

        if (!formValidacao.isValidTexto(element.endereco)) {
            formIsValid = false;
            errors[element + "_endereco"] = "Campo obrigatório.";
        } else {
            errors[element + "_endereco"] = undefined;
        }

        if (!formValidacao.isValidNumero(element.numero)) {
            formIsValid = false;
            errors[element + "_numero"] = "Campo obrigatório.";
        } else {
            errors[element + "_numero"] = undefined;
        }

        if (!formValidacao.isValidTexto(element.bairro)) {
            formIsValid = false;
            errors[element + "_bairro"] = "Campo obrigatório.";
        } else {
            errors[element + "_bairro"] = undefined;
        }

        if (!formValidacao.isValidComboBox(element.estado)) {
            formIsValid = false;
            errors[element + "_estado"] = "Campo obrigatório.";
        } else {
            errors[element + "_estado"] = undefined;
        }

        if (!formValidacao.isValidTexto(element.cidade)) {
            formIsValid = false;
            errors[element + "_cidade"] = "Campo obrigatório.";
        } else {
            errors[element + "_cidade"] = undefined;
        }

        if (!formValidacao.isValidCep(element.cep)) {
            formIsValid = false;
            errors[element + "_cep"] = "Campo obrigatório ou inválido.";
        } else {
            errors[element + "_cep"] = undefined;
        }

        this.setState({ error: false, isLoaded: true, errors: errors })
        return formIsValid;
    }
    async OnSubmitForm(e) {
        e.preventDefault();

        try {
            if (!this.formValidation()) return false;

            this.setState({ isLoadingBtn: true });
            var responsavelNovo = this.state.novoResponsavel;
            var responsavel = this.state.responsavel;
            var result = null;
            if (this.props.ResponsavelPedagogico) {
                result = await _responsavelService.AtualizarResponsavelPedagogicoMovimentacao(this.state.Matricula.idMatricula, responsavelNovo);
            } else {
                delete responsavelNovo.__typename;
                delete responsavelNovo.grauParentescoNome;
                delete responsavelNovo.codigoErp;
                delete responsavelNovo.codigoCliente;
                delete responsavelNovo.responsavelPF;
                delete responsavelNovo.tipoResponsavel;
                delete responsavelNovo.urlContrato;
                delete responsavelNovo.Estado;
                debugger;
                result = await _responsavelService.AtualizarResponsavelMovimentacao(this.state.Matricula.idMatricula, responsavelNovo);
            }

            if (result != undefined && result != null) {
                Message.ExibeMensagem("Responsavel atualizado com sucesso!!", '0', true);
                responsavelNovo.codigoCliente = result?.codigoCliente
                responsavel = responsavelNovo;
                this.setState({ isTroca:true });
            } else {
                if (result.value != undefined) {
                    Message.ExibeMensagem(result.value, "2", true);
                } else if (result.title != undefined) {
                    Message.ExibeMensagem(result.title, "2", true);
                } else {
                    Message.ExibeMensagem("Erro ao atualizar responsavel!", "2", true);
                }
            }
            this.setState({ isLoadingBtn: false, responsavel: responsavel });
        } catch (e) {
            console.error(e)
        } finally {
            if (this.props.handleShowCsat) this.props.handleShowCsat(csatList.trocaResponsavelFinanceiro)
        }
    }

    SelecioneGrauParentesco(grauParentesco) {
       
        var campos = this.state.novoResponsavel;
        campos["grauParentesco"] = grauParentesco.valor;

        this.setState({ novoResponsavel: campos });
        this.setState({ grauParentesco: grauParentesco.label });
    }

    SelecioneEstado(siglaEstado, nomeEstado) {
        var campos = this.state.novoResponsavel;
        campos["estado"] = siglaEstado;

        this.setState({ novoResponsavel: campos });
        this.setState({ nomeEstado: nomeEstado });
    }

    render() {
        const { responsavel, novoResponsavel, isLoading, trocarResponsavel, options, isLoadingCpf, isLoadingBtn, grauParentesco, nomeEstado, isTroca } = this.state;
        if (isLoading) {
            return (<div><h2>Loading...</h2></div>);
        } else {
            if (!trocarResponsavel) {
                return (
                    <div>
                        <h5>Responsável atual</h5><hr />
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <p>Nome</p>
                                <p>{responsavel?.nome}</p>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <p>Codigo Cliente</p>
                                <p>{responsavel?.codigoCliente}</p>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <p>CPF</p>
                                <p>{responsavel?.cpf}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-3">
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <button className="btn btn-primary" onClick={() => this.OnChangeTrocarResponsavel(true)}>Trocar Responsável</button>
                            </div>
                            <div className="col-lg-4 col-md-3">
                            </div>
                        </div>
                    </div>
                );
            } else {
                if (isTroca) {
                    let msg = `TROCA DE RESPONSÁVEL DA MATRÍCULA ${this.props.Matricula?.codigoMatricula} REALIZADO COM SUCESSO!` 
                    if (this.props.ResponsavelPedagogico)
                        msg = `TROCA DE RESPONSÁVEL PEDAGÓGICO REALIZADO COM SUCESSO!`
                    return (
                        <div >
                            <div className=" form-group d-flex justify-content-center">
                                <h3 style={{ color: '#0068b2' }}> <strong>{msg}</strong>
                                </h3>
                            </div>
                        </div>
                    );
                } else {

                    return (
                        <div className="row">
                            <div className="col-md-12">
                                <form className="pxy needs-validation" onSubmit={(e) => this.OnSubmitForm(e)}>
                                    <div className="row">
                                        <div className="col-sm-6 pb-3 ">
                                            <label>CPF*</label>
                                            <InputMask className={"txtBorder form-control " + formValidacao.cssCPF(this.state.errors[novoResponsavel + "_cpf"], novoResponsavel.cpf)} name="cpf" mask="999.999.999-99" value={novoResponsavel.cpf} onChange={(event) => this.change(event)} />
                                            {isLoadingCpf ? "Buscando..." : ""}
                                            <span style={{ color: "red" }}>{this.state.errors[novoResponsavel + "_cpf"]}</span>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 pb-3">
                                            <label>Nome completo*</label>
                                            <input type="text" className={"txtBorder form-control " + formValidacao.cssTexto(this.state.errors[novoResponsavel + "_nome"], novoResponsavel.nome)} name={"nome"} defaultValue={novoResponsavel.nome || ""} onChange={event => this.change(event)} />
                                            <span style={{ color: "red" }}>{this.state.errors[novoResponsavel + "_nome"]}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 pb-3">
                                            <label>Grau de parentesco com o(s) estudante(s)*</label>
                                            <Nav className='me-auto'>
                                                <NavDropdown className={"ddlFundoBranco2 ddlPadding0 form-control " +
                                                    formValidacao.cssComboBox(
                                                        this.state.errors["Grau Parentesco"],
                                                        novoResponsavel.grauParentesco
                                                    )}
                                                    title={(grauParentesco != null && grauParentesco != undefined && grauParentesco != "" ? grauParentesco : "Selecione o Grau Parentesco...")} id='collasible-nav-dropdown'>
                                                    {options?.map((option, idx) => (

                                                        <NavDropdown.Item key={idx} className="cssScroll" onClick={() => this.SelecioneGrauParentesco(option)} href='#'>
                                                            {option.label}
                                                        </NavDropdown.Item>
                                                    ))}
                                                </NavDropdown>
                                            </Nav>

                                            <div className="invalid-feedback">Selecione o grau de parentesco</div>
                                            <span style={{ color: "red" }}>{this.state.errors[novoResponsavel + "_grauParentesco"]}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 pb-3">
                                            <label>Celular*</label>
                                            <InputMask type="text" mask="(99) 99999-9999" className={"txtBorder form-control " + formValidacao.cssCelular(this.state.errors[novoResponsavel + "_celular"], novoResponsavel.celular)} name="celular"
                                                placeholder="(00) 00000 0000" value={novoResponsavel.celular || ""} onChange={event => this.change(event)}
                                            />

                                            <span style={{ color: "red" }}>{this.state.errors[novoResponsavel + "_celular"]}</span>
                                        </div>
                                        <div className="col-sm-6 pb-3">
                                            <label>Telefone fixo (Opcional)</label>
                                            <InputMask type="text" className={"txtBorder form-control " + formValidacao.cssTelefone(this.state.errors[novoResponsavel + "_telefone"], novoResponsavel.telefoneFixo)} mask="(99) 9999-9999" placeholder="(00) 0000 0000" value={novoResponsavel.telefoneFixo || ""} name="telefoneFixo" onChange={event => this.change(event)} />
                                            <span style={{ color: "red" }}>{this.state.errors[novoResponsavel + "_telefoneFixo"]}</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 pb-3">
                                            <label>E-mail*</label>
                                            <input type="email" className={"txtBorder form-control " + formValidacao.cssEmail(this.state.errors[novoResponsavel + "_email"], novoResponsavel.email)} defaultValue={novoResponsavel.email} name="email" onChange={event => this.change(event)} />
                                            <span style={{ color: "red" }}>{this.state.errors[novoResponsavel + "_email"]}</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 pb-3">
                                            <label>Data de Nascimento*</label>

                                            <input type="date" className={"txtBorder form-control " + formValidacao.cssData(this.state.errors[novoResponsavel + "_dataNascimento"], novoResponsavel.dataNascimento)} name="dataNascimento" defaultValue={novoResponsavel.dataNascimento ? new Date(novoResponsavel.dataNascimento).toISOString().slice(0, 10) : ""} onChange={event => this.change(event)} />
                                            <span style={{ color: "red" }}>{this.state.errors[novoResponsavel + "_dataNascimento"]}</span>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 pb-3">
                                            <label>CEP*</label>
                                            <InputMask type="text" className={"txtBorder form-control " + formValidacao.cssCep(this.state.errors[novoResponsavel + "_cep"], novoResponsavel.cep)} value={novoResponsavel.cep || ""} mask='99999-999' name="cep" onChange={event => this.change(event)} />
                                            <span style={{ color: "red" }}>{this.state.errors[novoResponsavel + "_cep"]}</span>
                                            <a id="link-cep" href="https://buscacepinter.correios.com.br/" target="_blank">Não lembro meu CEP</a>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 pb-3">
                                            <label>Endereço*</label>
                                            <input type="text" key={novoResponsavel.endereco} className={"txtBorder form-control " + formValidacao.cssTexto(this.state.errors[novoResponsavel + "_endereco"], novoResponsavel.endereco)} name="endereco" defaultValue={novoResponsavel.endereco} onChange={event => this.change(event)} />
                                            <span style={{ color: "red" }}>{this.state.errors[novoResponsavel + "_endereco"]}</span>

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 pb-3">
                                            <label>Número*</label>
                                            <input type="text" className={"txtBorder form-control " + formValidacao.cssNumero(this.state.errors[novoResponsavel + "_numero"], novoResponsavel.numero)} defaultValue={novoResponsavel.numero} name="numero" onChange={event => this.change(event)} />
                                            <span style={{ color: "red" }}>{this.state.errors[novoResponsavel + "_numero"]}</span>
                                        </div>

                                        <div className="col-sm-6 pb-3">
                                            <label>Complemento</label>
                                            <input type="text" className="txtBorder form-control" defaultValue={novoResponsavel.complemento} name="complemento" onChange={event => this.change(event)} />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 pb-3">
                                            <label>Bairro*</label>
                                            <input type="text" key={novoResponsavel.bairro} className={"txtBorder form-control " + formValidacao.cssTexto(this.state.errors[novoResponsavel + "_bairro"], novoResponsavel.bairro)} name="bairro" defaultValue={novoResponsavel.bairro} onChange={event => this.change(event)} />
                                            <span style={{ color: "red" }}>{this.state.errors[novoResponsavel + "_bairro"]}</span>
                                        </div>
                                        <div className="col-sm-6 pb-3">
                                            <label>Cidade*</label>
                                            <input type="text" key={novoResponsavel.cidade} className={"txtBorder form-control " + formValidacao.cssComboBox(this.state.errors[novoResponsavel + "_cidade"], novoResponsavel.cidade)} name="cidade" defaultValue={novoResponsavel.cidade} onChange={event => this.change(event)} />
                                            <span style={{ color: "red" }}>{this.state.errors[novoResponsavel + "_cidade"]}</span>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-sm-6 pb-3">
                                            <label>Estado*</label>
                                            <Nav className='me-auto'>
                                                <NavDropdown className={"ddlFundoBranco2 ddlPadding0 form-control " +
                                                    formValidacao.cssComboBox(
                                                        this.state.errors[novoResponsavel + "_estado"],
                                                        novoResponsavel.estado
                                                    )}
                                                    title={(nomeEstado != null && nomeEstado != undefined && nomeEstado != "" ? nomeEstado : "Selecione o Estado...")} id='collasible-nav-dropdown'>

                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('AC', 'Acre')} href='#'>Acre</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('AL', 'Alagoas')} href='#'>Alagoas</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('AP', 'Amapá')} href='#'>Amapá</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('AM', 'Amazonas')} href='#'>Amazonas</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('BA', 'Bahia')} href='#'>Bahia</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('CE', 'Ceará')} href='#'>Ceará</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('DF', 'Distrito Federal')} href='#'>Distrito Federal</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('ES', 'Espírito Santo')} href='#'>Espírito Santo</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('GO', 'Goiás')} href='#'>Goiás</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('MA', 'Maranhão')} href='#'>Maranhão</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('MT', 'Mato Grosso')} href='#'>Mato Grosso</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('MS', 'Mato Grosso do Sul')} href='#'>Mato Grosso do Sul</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('MG', 'Minas Gerais')} href='#'>Minas Gerais</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('PA', 'Pará')} href='#'>Pará</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('PB', 'Paraíba')} href='#'>Paraíba</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('PR', 'Paraná')} href='#'>Paraná</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('PE', 'Pernambuco')} href='#'>Pernambuco</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('PI', 'Piauí')} href='#'>Piauí</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('RJ', 'Rio de Janeiro')} href='#'>Rio de Janeiro</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('RN', 'Rio Grande do Norte')} href='#'>Rio Grande do Norte</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('RS', 'Rio Grande do Sul')} href='#'>Rio Grande do Sul</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('RO', 'Rondônia')} href='#'>Rondônia</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('RR', 'Roraima')} href='#'>Roraima</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('SC', 'Santa Catarina')} href='#'>Santa Catarina</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('SP', 'São Paulo')} href='#'>São Paulo</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('SE', 'Sergipe')} href='#'>Sergipe</NavDropdown.Item>
                                                    <NavDropdown.Item className="cssScroll" onClick={() => this.SelecioneEstado('TO', 'Tocantins')} href='#'>Tocantins</NavDropdown.Item>
                                                </NavDropdown>
                                            </Nav>

                                            <span style={{ color: "red" }}>{this.state.errors[novoResponsavel + "_estado"]}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 pb-3">
                                            <button className="btn btn-primary" style={{ marginRight: 5 }} onClick={() => this.OnChangeTrocarResponsavel(false)}>Voltar</button>
                                        </div>
                                        <div className="col-sm-6 pb-3">
                                            <button type="submit" className="btn btn-primary " disabled={isLoadingBtn ? "disabled" : ""}>{isLoadingBtn ? "Salvando..." : "Finalizar"}</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    )
                }
            }
        }
    }
}
