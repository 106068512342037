import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function getAmbientes() {
    const client = window.apolloClient;
    const {
        data: {
            getAmbienteAtualCore
        },
        errors
    } = await client.query({
        query: gql`
            query { 
                getAmbienteAtualCore {
                    crmUrl
                    erpUrl
                    coreUrl
                }
            }
        `,
    });

    dispatchGraphError(errors);

    return getAmbienteAtualCore;
}
