//#### Valida Preenchimento #####
export function isValidPreenchido(str) {
    var result = false;

    if (str != null && str != undefined) {
        var regex = /[^\W_]*\d+[^\W_]*/g;

        var returnRegex = str.toString().match(regex);

        if (returnRegex != null && returnRegex.join('').length > 0) {
            result = true;
        }

    }

    return result;
}

//#### CPF #####
export function cssCPF(error, str) {
    var regex = /\d+/g;
    var result = "";

    if (str != null && str != undefined) {

        var returnRegex = str.toString().match(regex);
        var valido = isValidCPF(str);
        

        if ((error != undefined && error != "" && !valido) || (!valido && returnRegex != null && returnRegex.join('').length == 11)) {
            result = "validError"
        } else if (valido) {
            result = "validOk"
        }
    }

    return result;
}


export function isValidCPF(cpf) {
    let i;
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
        !cpf ||
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999"
    ) {
        return false
    }
    let soma = 0;
    let resto;
    for (i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    if (resto !== parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    return resto === parseInt(cpf.substring(10, 11));
}

//#### CNPJ #####
export function cssCNPJ(error, str) {
    var regex = /\d+/g;
    var result = "";

    if (str != null && str != undefined) {

        var returnRegex = str.toString().match(regex);
        var valido = isValidCNPJ(str);

        if ((error != undefined && error != "" && !valido) || (!valido && returnRegex != null && returnRegex.join('').length == 14)) {
            result = "validError"
        } else if (valido) {
            result = "validOk"
        }
    }

    return result;
}

export function isValidCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }

    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;
}

//#### CPF/CNPJ #####
export function cssCPFCNPJ(error, str) {
    var result = "";
    var valido = null;
    
    if (str != null && str != undefined) {

        valido = isValidCpfCnpj(str);
        
        if (error != undefined && error != "" && !valido) {
            result = "validError"
        } else if (valido) {
            result = "validOk"
        }
    }

    return result;
}

export function isValidCpfCnpj(str) {
    var regex = /\d+/g;
    var result = "";
    var cpfcnpj = ""
    var valido = null;

    if (str != null && str != undefined) {

        var returnRegex = str.toString().match(regex);

        if (returnRegex != null && returnRegex != undefined) {
            cpfcnpj = returnRegex.join('');
        }

        if (cpfcnpj.length <= 11) {
            valido = isValidCPF(cpfcnpj);
        } else {
            valido = isValidCNPJ(cpfcnpj);
        }

        result = valido;
    }

    return result;
}

//#### RG #####
export function cssRG(error, str) {
    var regex = /\d+/g;
    var result = "";

    if (str != null && str != undefined) {

        var returnRegex = str.toString().match(regex);
        var valido = isValidRG(str);

        if ((error != undefined && error != "" && !valido)) {
            result = "validError"
        } else if (valido) {
            result = "validOk"
        }
    }

    return result;
}


export function isValidRG(str) {
    var regex = /\d+/g;
    var result = false;

    if (str != null && str != undefined) {

        var returnRegex = str.toString().match(regex);

        if (returnRegex != null && returnRegex.join('').length == 9) {
            result = true;
        }
    }

    return result;
}

//#### SENHA #####
export function cssSenha(error, str, senhaCoparar = undefined) {

    var result = "";
    var valido = isValidSenha(str);
    

    if ((valido && senhaCoparar == undefined) || (valido && senhaCoparar != undefined && senhaCoparar == str)) {
        result = "validOk"
    } else if ((error != undefined && error != null && !valido) || (senhaCoparar != undefined && senhaCoparar != str && str != "")) {
        result = "validError"
    }

    return result;
}

export function isValidSenha(str) {
    var result = false;

    if (str != undefined && str != null && str.toString().trim().length >= 1 && str.toString().trim() != '') {
        result = true;
    }

    return result;
}


//#### TEXTO #####
export function cssTexto(error, str, minLimit = 1, maxLimit = 0) {
    var result = "";
    var valido = isValidTexto(str, minLimit, maxLimit);


    if (valido) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isValidTexto(str, minLimit = 1, maxLimit = 0) {
    var result = true;

    if (str == undefined || str == null) {
        return false;
    }

    if (str.toString().trim().length < minLimit) {
        return false;
    }

    if (maxLimit > 0 && str.toString().trim().length > maxLimit) {
        return false;
    }

    return result;
}

//#### COMBO BOX #####
export function cssComboBox(error, str) {
    var result = "";
    var valido = isValidComboBox(str);

    if (valido) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isValidComboBox(str) {
    var result = false;

    if (str != null && str != undefined && str.toString().trim() != "" && str.toString().trim() != "0") {
        result = true;
    }

    return result;
}

//#### CELULAR COM DDD #####
export function cssCelular(error, str) {
    var result = "";
    var valido = isValidCelular(str);

    if (valido) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isValidCelular(str) {
    var regex = /\d+/g;
    var result = false;

    if (str != null && str != undefined) {

        var returnRegex = str.toString().match(regex);

        if (returnRegex != null && returnRegex.join('').length == 11) {
            result = true;
        }
    }

    return result;
}

//#### TELEFONE COM DDD #####
export function cssTelefone(error, str) {
    var result = "";
    var valido = isValidTelefone(str);

    if (valido) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isValidTelefone(str) {
    var regex = /\d+/g;
    var result = false;

    if (str != null && str != undefined) {

        var returnRegex = str.toString().match(regex);

        if (returnRegex != null && returnRegex.join('').length == 10) {
            result = true;
        }
    }

    return result;
}

//#### EMAIL #####
export function cssEmail(error, str, minLimit = 1, maxLimit = 0) {
    var result = "";
    var valido = isValidEmail(str, minLimit, maxLimit);

    if (valido) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isValidEmail(str, minLimit = 1, maxLimit = 0) {
    var result = true;

    if (str == undefined || str == null) {
        return false;
    }

    if (str.toString().trim().length < minLimit) {
        return false;
    }

    if (maxLimit > 0 && str.toString().trim().length > maxLimit) {
        return false;
    }

    let validaemail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!validaemail.test(str.toString().trim())) {
        return false;
    }

    return result;
}

//#### CEP #####
export function cssCep(error, str) {
    var result = "";
    var valido = isValidCep(str);
    
    if (valido) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isValidCep(str) {
    var result = false;

    if (str != undefined && str != null) {
        let regex = /\d+/g;
        var returnRegex = str.toString().match(regex);

        if (returnRegex != null && returnRegex.join('').length == 8) {
            result = true;
        }
    }

    return result;
}

//#### NUMERICO #####
export function cssNumero(error, str) {
    var result = "";
    var valido = isValidNumero(str);

    if (valido) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isValidNumero(str) {
    var result = true;

    if (str == undefined || str == null || str.trim() == "") {
        result = false;
    }

    if (isNaN(str)) {
        result = false;
    }

    return result;
}


//#### PERCENTUAL #####
export function cssPercentual0A100(error, str) {
    var result = "";
    var valido = isPercentual0A100(str);

    if (valido) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isPercentual0A100(str) {
    var result = false;

    if (str != undefined && str != null) {
        let regex = /^((100)|(\d{1,2}(\.\d*)?))%$/g;
        var returnRegex = str.toString().match(regex);

        if (returnRegex != null) {
            result = true;
        }
    }

    return result;
}

//#### DATA #####
export function cssData(error, str) {
    var result = "";
    var valido = isValidData(str);
    
    if (valido) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isValidData(dateString) {
    let regex = "";
    var returnRegex = "";
    var parts = null;
    var day = 0
    var month = 0
    var year = 0

    if (dateString != null && dateString != undefined && dateString != "") {

        regex = /\d{4}-\d{2}-\d{2}/g;

        returnRegex = dateString.toString().match(regex);

        if (returnRegex != null) {
            // Parse the date parts to integers
            parts = returnRegex.join('').split("-");
            day = parseInt(parts[2], 10);
            month = parseInt(parts[1], 10);
            year = parseInt(parts[0], 10);
        } else {
            regex = /\d{2}-\d{2}-\d{4}/g;
            returnRegex = dateString.toString().match(regex);

            if (returnRegex != null) {
                parts = returnRegex.join('').split("-");
                day = parseInt(parts[0], 10);
                month = parseInt(parts[1], 10);
                year = parseInt(parts[2], 10);
            }
        }

    } else {
        return false;
    }

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) {
        return false;
    }

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
        monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
};

//#### DOCUMENTO #####
export function cssDocumento(error, file) {
    var result = "";
    
    var valido = isValidDocumento(file);


    if (valido) {
        result = "validOkDocumento"
    } else if (error != undefined && error != null && !valido) {
        result = "validErrorDocumento"
    }

    return result;
}

export function isValidDocumento(file) {
    var result = false;
    
    if (file != undefined && file != null) {
        if (file.url != undefined && file.url != null && file.url.trim() != "") {
            result = true;
        } 

        if (file.preview != undefined && file.preview != null && file.preview.trim() != "") {
            result = true;
        }
    }

    return result;
}


//#### CARTAO #####
export function cssCartao(error, str, token) {
    var result = "";
    var valido = isValidCartao(str);

    if (valido || (!valido && token != null && token != undefined && token != "" && token.length > 1)) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isValidCartao(str) {
    var result = false;

    if (str != undefined && str != null) {
        let regex = /\d+/g;
        var returnRegex = str.toString().match(regex);
        var strRegex = "";

        if (returnRegex != null) {
            strRegex = returnRegex.join('');
        }

        if (strRegex != null && strRegex.length > 2) {

            if (strRegex.substring(0, 1) == '4') {
                if (strRegex.length == 13 || strRegex.length == 16) {
                    return true;
                } else {
                    return false;
                }
            }

            if ((strRegex.substring(0, 1) == '5')) {
                if (strRegex.length == 16) {
                    return true;
                } else {
                    return false;
                }
            }
            
            if ((strRegex.substring(0, 2) == "34" || strRegex.substring(0, 2) == "37")) {
                if (strRegex.length == 15) {
                    return true;
                } else {
                    return false;
                }
            }

            if ((strRegex.substring(0, 2) == "36" || strRegex.substring(0, 2) == "38" || strRegex.substring(0, 3) == "301" || strRegex.substring(0, 3) == "305")) {
                if ((strRegex.length == 14 || strRegex.length == 16)) {
                    return true;
                } else {
                    return false;
                }
            }

            if (strRegex.length == 16) {
                return true;
            }
        }
    }

    return result;
}

//#### CARTAO MES ANO #####
export function cssMesAno(error, str) {
    var result = "";
    var valido = isValidMesAno(str);

    if (valido) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isValidMesAno(str) {
    var result = false;

    if (str != undefined && str != null) {
        let regex = /\d+/g;
        var returnRegex = str.toString().match(regex);

        if (returnRegex != null) {
            var strNum = returnRegex.join('');

            if (strNum.length > 2 && parseInt(strNum.substring(0, 2)) > 0 && parseInt(strNum.substring(0, 2)) <= 12 && strNum.length == 6) {
                result = true;
            }
            
        }
    }

    return result;
}

//#### CARTAO VALIDA CVV #####
export function cssCVV(error, str) {
    var result = "";
    var valido = isValidCVV(str);

    if (valido) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isValidCVV(str) {
    var result = false;

    if (str != undefined && str != null) {
        let regex = /\d+/g;
        var returnRegex = str.toString().match(regex);

        if (returnRegex != null) {
            var strNum = returnRegex.join('');

            if (strNum.length == 3 || strNum.length == 4) {
                result = true;
            }
        }
    }

    return result;
}


//#### AGENCIA #####
export function cssAgencia(error, str) {
    var result = "";
    var valido = isValidAgencia(str);

    if (valido) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isValidAgencia(str) {
    var result = false;

    if (str != undefined && str != null) {
        let regex = /\d+/g;
        var returnRegex = str.toString().match(regex);

        if (returnRegex != null) {
            var strNum = returnRegex.join('');

            if (strNum.length == 4) {
                result = true;
            }
        }
    }

    return result;
}

//#### CONTA CORRENTE #####
export function cssContaCorrente(error, str) {
    var result = "";
    var valido = isValidContaCorrente(str);

    if (valido) {
        result = "validOk"
    } else if (error != undefined && error != null && !valido) {
        result = "validError"
    }

    return result;
}

export function isValidContaCorrente(str) {
    var result = false;

    if (str != undefined && str != null) {
        let regex = /\d+/g;
        var returnRegex = str.toString().match(regex);

        if (returnRegex != null) {
            var strNum = returnRegex.join('');

            if (strNum.length >= 4) {
                result = true;
            }
        }
    }

    return result;
}