import { useEffect, useState } from 'react';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import withAppContext from "../../hocs/withAppContext";
import moment from 'moment'
import * as _responsavelService from "../../service/responsavel-service";
import * as _matriculaService from "../../service/matricula-service";
import * as Message from "../../components/shared/Message";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import LoadingAnimation from '../../components/LoadingAnimation/LoadigAnimation';

import './styles.css'


const DeclaracaoDeBolsa = (props: any) => {
    const { Matricula, Unidade } = props;
    const serieMatricula = Matricula.servico.split('-')[2];
    const [responsavel, setResponsavel] = useState('');
    const [errorText, setErrorText] = useState('');
    const [bolsa, setBolsa] = useState('');
    const [isloading, setIsLoading] = useState(true);
    const [erroRequisicao, setErroRequisicao] = useState(false);
    const data = moment().format('DD/MM/YYYY')

    const printPDF = () => {
        window.print();
    };
    useEffect(() => {
        setErroRequisicao(false);
        setIsLoading(true);
        Promise.all([GetResponsavelByMatricula(), buscaMatriculaGeral()])
    }, [Matricula])

    const buscaMatriculaGeral = async () => {
        var matriculaGeral = await _matriculaService.GetMatriculaGeral(Matricula.idMatricula);
        if (matriculaGeral && matriculaGeral.bolsaVigente && matriculaGeral.bolsaVigente.percentual) {
            setBolsa(matriculaGeral.bolsaVigente.percentual.toFixed(2));
        } else {
            Message.ExibeMensagem("A bolsa vigente da matrícula não foi encontrada!", "2", false);
            setErroRequisicao(true);
            setErrorText('A bolsa vigente da matrícula não foi encontrada!');
        }
        setIsLoading(false);
    }

    const GetResponsavelByMatricula = async () => {
        var responsavel = await _responsavelService.GetResponsavelByMatricula(Matricula.idMatricula);
        if (responsavel && responsavel.nome) {
            setResponsavel(responsavel.nome);
        } else {
            Message.ExibeMensagem("O responsável da matrícula não foi encontrado!", "2", false);
            setErroRequisicao(true);
            setErrorText('O responsável da matrícula não foi encontrado!');
        }
    }
    
    if (erroRequisicao) {
        return <ErrorMessage message={errorText} />
    }
    if (isloading) {
        return (<LoadingAnimation> </LoadingAnimation>)
    }
    return (
        <>
            <div className='print-content'>
                <button className="print-button" onClick={printPDF}>Imprimir</button>
            </div>
            <div className="declaracao-component">
                <div className="declaracao-container">
                    <h5 className='titulo'>SEB SISTEMA EDUCACIONAL BRASILEIRO S.A</h5>
                    <div className='subtitulo texto-variavel-paragrafo'>Matrícula: <div> {Matricula.codigoMatricula} - {Matricula.anoLetivo_Name}</div></div>
                    <h4>DECLARAÇÃO</h4>
                    <div className='texto-variavel-paragrafo'>Eu,&nbsp;<div>{responsavel}</div>&nbsp; responsável pelo aluno(a)&nbsp;<div>{Matricula.estudante_Name}</div>, aluno(a) regularmente matrículado(a) na série/ano:&nbsp;<div>{serieMatricula}&nbsp;/&nbsp;{Matricula.anoLetivo_Name}</div></div>
                    <div className='texto-variavel-paragrafo'>DECLARO, para os fins que se fizerem necessários, que recebi do&nbsp;<div>{Unidade.nome}</div>&nbsp;uma bolsa de estudo, nos termos dos regulamentos internos da instituição, de&nbsp;<div>{bolsa}%</div>. </div>
                    <p>Outrossim, ainda declaro que o referido desconto, dentre outros descontos eventualmente podem ser cancelados caso ocorra uma das seguintes hipóteses ao Aluno que, a qualquer tempo, durante o ano vigente tenha:</p>
                    <p>a. Mantida frequência mínima abaixo de 85% (oitenta e cinco por cento) de presença em qualquer disciplina;</p>
                    <p>b. Acumulado mais de 03 (três) recuperações em disciplinas;</p>
                    <p>c. Ficado inadimplente por mais de 60 (sessenta) dias consecutivos;</p>
                    <p>d. Apresentado em seu currículo escolar qualquer anotação de indisciplina caracterizada como GRAVE, conforme disposição contida no "Regimento Escolar / Interno" e ao "Manual de Convivência"</p>
                    <p>Os descontos concedidos são válidos exclusivamente para o presente ano letivo, podendo ser renovados de acordo com as condições mencionadas acima. Os descontos concedidos aplicam-se somente sobre a Prestação de Serviços Educacionais regulares, não sobre serviços adicionais (Upselling), Material Escolar e Livros.</p>
                    <div className='texto-variavel-paragrafo'>Bolsa:&nbsp;<div>{bolsa}%</div></div>
                    <p>Por ser a expressão da verdade, assino a presente.</p>
                    <div className="declaracao-footer">
                        <div>
                            <div className='texto-variavel-paragrafo'>LOCAL:&nbsp;<div>{Unidade.cidade}/{Unidade.estado}</div></div>
                            <br />
                            <hr />
                            <p>Responsável pela INSTITUIÇÃO</p>
                        </div>
                        <div>
                            <div className='texto-variavel-paragrafo'>DATA:&nbsp;<div>{data}</div></div>
                            <br />
                            <hr />
                            <div className="legenda-footer">
                                <div className='texto-variavel-paragrafo'><div>{responsavel}</div></div>
                                <p>Responsável Legal</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withAppContext(withUnidadeMatricula(DeclaracaoDeBolsa, 'Declaracao De Bolsa'));