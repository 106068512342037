import React, {Component} from "react";
import * as Message from '../shared/Message';
import * as _integracaoMatriculaService from '../../service/integracaoMatricula-service';
import {Slider} from "@mui/material";

export class ReplayIntegracaoReservaParcial extends Component {
  static displayName = ReplayIntegracaoReservaParcial.name;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      matriculaCrm: "",
      qntParcela: "",
      dataReferencia: "",
      result: {},
      request: {},
      selectedFile: null,
    };
  }

  change(event) {
    if (event.target.name == "qntParcela") {
      console.log(event.target.value)
      this.setState({qntParcela: event.target.value});
    } else if (event.target.name == "dataReferencia") {
      this.setState({dataReferencia: event.target.value});
    } else {
      this.setState({matriculaCrm: event.target.value});
    }
  }

  changeCheckBox(event) {

    var request = this.state.request;

    if (event.target.name == "ficha") {
      if (event.target.checked) {
        request.enviarFichaFinanceira = true;
      } else {
        request.enviarFichaFinanceira = false;
      }
    }

    if (event.target.name == "transacao") {
      if (event.target.checked) {
        request.enviarTransacaoFinanceira = true;
      } else {
        request.enviarTransacaoFinanceira = false;
      }
    }

    if (event.target.name == "servicomatricula") {
      if (event.target.checked) {
        request.enviarServicoMatricula = true;
      } else {
        request.enviarServicoMatricula = false;
      }
    }

    this.setState({ request: request });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({result: {}})

    this.setState({isLoading: true});

    const result = await _integracaoMatriculaService.ReplayParcialReserva({
      codigoMatricula: this.state.matriculaCrm,
      qntParcela: parseInt(this.state.qntParcela),
      dataReferencia: this.state.dataReferencia,
      envioItems: this.state.request
    })

    this.setState({
      isLoading: false,
      result,
      request: {}
    });

    if (this.state.result != null) {
      Message.ExibeListMensagem(this.state.result.messages);
    } else {
      Message.ExibeMensagem("Não foi possível obter retorno da rotina, verifique se código ERP foi criado na matrícula", "2");
    }
  };

  render() {
    return (
      <div className="panel">
        <h2 className="tituloCss">Replay Integração Parcial de Reserva</h2>
        <div className="panel-body p-25 mb-3">
          <div className="col-md-12 col-lg-12">

            <form onSubmit={this.handleSubmit} asp-controller="CheckOut" asp-action="ReplayIntegracaoUpSellingTotal">

              <div className="row">
                <div className="col-md-3 col-lg-3">
                  <strong>Código Matrícula Reserva</strong>
                  <input type="text" className={"txtBorder form-control "} name={"matriculaCrm"}
                         onChange={event => this.change(event)}/>
                </div>
                <div className="col-md-3 col-lg-3">
                  <strong>Data de Referência</strong>
                  <input type="date" className={"txtBorder form-control "} name={"dataReferencia"}
                         onChange={event => this.change(event)}/>
                </div>
              </div>
              <br/>
              <div className="row">
                <div className="col-md-8 col-lg-8">
                  <strong>Quantidade Parcela</strong>
                  <Slider
                    value={this.state.qntParcela}
                    onChange={event => this.change(event)}
                    aria-labelledby="qtd-parcelas"
                    name="qntParcela"
                    min={0}
                    max={12}
                    sx={{
                      ml: 3
                    }}
                    marks={Array.from(Array(13), (_, x) => x).map((parcela) => (
                      {
                        value: parcela,
                        label: parcela === 0 ? "Á vista" : parcela.toString() + "x",
                      }
                    ))}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-lg-2">
                  <label>
                    <input type="checkbox" className="checkbox" name="ficha"
                           onChange={event => this.changeCheckBox(event)}/>
                    Enviar Ficha
                  </label>
                </div>
                <div className="col-md-2 col-lg-2">
                  <label>
                    <input type="checkbox" className="checkbox" name="transacao"
                           onChange={event => this.changeCheckBox(event)}/>
                    Enviar Transações
                  </label>
                </div>
                <div className="col-md-2 col-lg-2">
                  <label>
                    <input type="checkbox" className="checkbox" name="servicomatricula"
                           onChange={event => this.changeCheckBox(event)}/>
                    Enviar Serviço Matricula
                  </label>
                </div>
              </div>
              <div className="col-md-2 col-lg-2 pt-4">
                <button
                  className="btn btn-primary mb-3"
                  type="submit"
                  disabled={this.state.isLoading ? "disabled" : ""}
                >{this.state.isLoading ? "Enviando..." : "Enviar"}</button>
              </div>
            </form>
          </div>
        </div>

      </div>
    );
  }
}
