import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import {Unidade} from "../../components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";

export const unidadesState = atom<Unidade[]>({
  key: 'unidadesState',
  default: [],
  effects: [
    persistAtomEffect
  ]
});
