import {gql} from "@apollo/client";
import {ExibeMensagem} from "../components/shared/Message";
import {dispatchGraphError} from "../utils/dispatchGraphError";

/**
 * 
 * @param idMatricula
 * @returns {Promise<SecretariaManutencaoFinanceiraFichaResponse[]>}
 */
export async function GetFichaFinanceiraByIdMatricula(idMatricula) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  return client.query({
    fetchPolicy: 'no-cache',
    query: gql`
        query ListManutencaoFinanceiraFichaByIdMatricula($idMatricula: String) {
            listManutencaoFinanceiraByIdMatricula(idMatricula: $idMatricula) {
                value {
                    idTitulo
                    idFicha
                    numeroFatura
                    item
                    descricao
                    configuracao
                    metodoPagamento
                    dataVencimento
                    valorBruto
                    descontoBolsa
                    descontoMetodo
                    descontoReserva
                    valorLiquido
                }
                messages {
                    text
                    typeMessage
                }
                statusCode
            }
        }
    `,
    variables: {
      idMatricula
    }
  }).then(resp => {
    const {
      data: {
        listManutencaoFinanceiraByIdMatricula: {
          value,
          messages,
        }
      },
      errors
    } = resp;

    dispatchGraphError(errors);

    if (messages.length > 0) {
      messages.forEach(message => {
        ExibeMensagem(message.text, message.typeMessage, true);
      });
    }

    return value || [];
  })
    .catch(console.error)
}

/**
 *
 * @returns {Promise<SecretariaManutencaoFinanceiraFichaResponse>}
 * @param {string} matriculaId
 * @param {SecretariaManutencaoFinanceiraFichaResponse[]} fichas
 */
export async function DeleteFicha(matriculaId, fichas = []) {
  fichas.forEach(ficha => delete ficha.__typename);
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  return client.mutate({
    mutation: gql`
        mutation SalvarManutencaoFinanceira($manutencaoFinanceira: ManutencaoFinanceiraInput!) {
            salvarManutencaoFinanceira(manutencaoFinanceira: $manutencaoFinanceira) {
                value
                messages {
                    text
                    typeMessage
                }
                statusCode
            }
        }
    `,
    variables: {
      manutencaoFinanceira: {
        matriculaId,
        create: [],
        update: [],
        delete: fichas,
      }
    }
  }).then(resp => {
    const {
      data: {
        salvarManutencaoFinanceira: {
          value,
          messages,
        }
      },
      errors
    } = resp;

    dispatchGraphError(errors);

    if (messages.length > 0) {
      messages.forEach(message => {
        ExibeMensagem(message.text, message.typeMessage, true);
      });
    }

    return value || [];
  })
  .catch(console.error)
}

/**
 *
 * @returns {Promise<SecretariaManutencaoFinanceiraFichaResponse>}
 * @param {string} matriculaId
 * @param {SecretariaManutencaoFinanceiraFichaResponse[]} create
 * @param {SecretariaManutencaoFinanceiraFichaResponse[]} update
 */
export async function CreateUpdateFicha(matriculaId, create = [], update = []) {
  create.forEach(ficha => {
    delete ficha.__typename;
    delete ficha.id;
    delete ficha.metadata;
  });
  update.forEach(ficha => {
    delete ficha.__typename;
    delete ficha.id;
    delete ficha.metadata;
  });
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  return client.mutate({
    mutation: gql`
        mutation SalvarManutencaoFinanceira($manutencaoFinanceira: ManutencaoFinanceiraInput!) {
            salvarManutencaoFinanceira(manutencaoFinanceira: $manutencaoFinanceira) {
                value
                messages {
                    text
                    typeMessage
                }
                statusCode
            }
        }
    `,
    variables: {
      manutencaoFinanceira: {
        matriculaId,
        create: create,
        update: update,
        delete: [],
      }
    }
  }).then(resp => {
    const {
      data: {
        salvarManutencaoFinanceira: {
          value,
          messages,
        }
      },
      errors
    } = resp;

    dispatchGraphError(errors);

    if (messages.length > 0) {
      messages.forEach(message => {
        ExibeMensagem(message.text, message.typeMessage, true);
      });
    }

    return value || [];
  })
  .catch(console.error)
}

/**
 *
 * @param idMatricula
 */
export async function GetFicha(idMatricula, tries = 3) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  return client.query({
    fetchPolicy: 'no-cache',
    query: gql`
        query GetManutencaoFinanceiraFicha($idMatricula: String) {
            getManutencaoFinanceiraFicha(idMatricula: $idMatricula) {
                value {
                    dataAreaId
                    matriculaId
                    id
                    fiscalEstablishmentId
                    financialStatementList {
                        paymentStatus
                        id
                        installmentAmountCur
                        installmentNetAmount
                        paymentMethod
                        totalDiscount
                        cashdiscDueDate
                        cashDiscValue
                        dueDate
                        invoiceId
                        itemId
                        installment
                        configId
                        responsible
                        transDate
                        reserveValue
                        scholarshipValue
                        paymMethodDiscAmount
                        transactionType
                        typeOfMovement
                        movementDate
                        matriculaId
                        amountPaid
                        typeOfPayment
                        agreementDocument
                        refCustTrans
                        refCustTransOpen
                        refCustSettlement
                    }
                }
                messages {
                    text
                    typeMessage
                }
                statusCode
            }
        }
    `,
    variables: {
      idMatricula
    }
  }).then(resp => {
    const {
      data: {
        getManutencaoFinanceiraFicha: {
          value,
          messages,
        }
      },
      errors
    } = resp;

    dispatchGraphError(errors);

    if (messages.length > 0) {
      const isObjectReferenceError = messages.some(message => message.text.includes("Object reference not set to an instance of an object."));

      if (isObjectReferenceError && tries > 0) {
        return GetFicha(idMatricula, tries - 1);
      } else {
        messages.forEach(message => {
          ExibeMensagem(message.text, message.typeMessage, true);
        });
      }
    }

    return value || {};
  })
  .catch(console.error)
}

/**
 *
 * @param idMatricula
 */
export async function GetOV(idMatricula) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  return client.query({
    fetchPolicy: 'no-cache',
    query: gql`
        query GetManutencaoFinanceiraOV($idMatricula: String) {
            getManutencaoFinanceiraOV(idMatricula: $idMatricula) {
                value {
                    matriculaId
                    configId
                    itemId
                    custAccount
                    dataAreaId
                    month
                    year
                    amountCur
                    discAmountCur
                    inventLocationId
                    fiscalEstablishment
                    paymSched
                    scholarship
                    grossAmount
                    methodDiscount
                    billingItemId
                    typeOfMovement
                    deliveryDate
                    startSchoolYear
                    cashDiscAmount
                    methodDiscountPercentage
                    netValue
                    enrollmentSalesOrderRecId
                    installmentNum
                    segmentCode
                    scholarshipPercentage
                    cashDiscAmountCur
                }
                messages {
                    text
                    typeMessage
                }
                statusCode
            }
        }
    `,
    variables: {
      idMatricula
    }
  }).then(resp => {
    const {
      data: {
        getManutencaoFinanceiraOV: {
          value = [],
          messages,
        }
      },
      errors
    } = resp;

    dispatchGraphError(errors);

    if (messages.length > 0) {
      messages.forEach(message => {
        ExibeMensagem(message.text, message.typeMessage, true);
      });
    }

    return value || [];
  })
  .catch(console.error)
}

/**
 * @param idMatricula
 * @returns {Promise<Transacao[]>}
 * @constructor
 */
export async function GetTransacoes(idMatricula) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  return client.query({
    fetchPolicy: 'no-cache',
    query: gql`
        query GetManutencaoFinanceiraFicha($idMatricula: String) {
            getManutencaoFinanceiraTransacao(idMatricula: $idMatricula) {
                value {
                    DataAreaId
                    MatriculaId
                    FiscalEstablishmentId
                    financialTransDetail {
                        financialMovType
                        custAccount
                        transStatus
                        transactions {
                            service {
                                firstInstallment {
                                    transAmountCur
                                    dueDate
                                    invoiceId
                                    cashDiscAmount
                                    cashDiscDate
                                    configId
                                    installmentNum
                                    invoiceDate
                                    itemId
                                    offsetAccountNum
                                    offSetAccountType
                                    paymMode
                                    paymSpec
                                    paymTermId
                                    qtyOfInstallments
                                    transTxt
                                }
                                monthlyPayment {
                                    bankAccount {
                                        bankId
                                        bankAgency
                                        bankAgencyDigit
                                        bankAccountNum
                                        bankAccountDigit
                                    }
                                    bankingBillet {
                                        barCode
                                        companyDocument
                                        digitableLine
                                        ourNumber
                                        ourNumberDigit
                                        transactionDate
                                    }
                                    custCard {
                                        processorCode
                                        brand
                                        authorizationNumber
                                        nsu
                                        cv
                                        chargeId
                                        transactionDate
                                        paymentDate
                                        paymId
                                        customerId
                                        recurrent {
                                            cardId
                                            planId
                                            signId
                                        }
                                    }
                                    transAmountCur
                                    dueDate
                                    invoiceId
                                    cashDiscAmount
                                    cashDiscDate
                                    configId
                                    installmentNum
                                    invoiceDate
                                    itemId
                                    offsetAccountNum
                                    offSetAccountType
                                    paymMode
                                    paymSpec
                                    paymTermId
                                    qtyOfInstallments
                                    transTxt
                                }
                            }
                            material {
                                firstInstallment {
                                    bankAccount {
                                        bankId
                                        bankAgency
                                        bankAgencyDigit
                                        bankAccountNum
                                        bankAccountDigit
                                    }
                                    bankingBillet {
                                        barCode
                                        companyDocument
                                        digitableLine
                                        ourNumber
                                        ourNumberDigit
                                        transactionDate
                                    }
                                    custCard {
                                        processorCode
                                        brand
                                        authorizationNumber
                                        nsu
                                        cv
                                        chargeId
                                        transactionDate
                                        paymentDate
                                        paymId
                                        customerId
                                        recurrent {
                                            cardId
                                            planId
                                            signId
                                        }
                                    }
                                    transAmountCur
                                    dueDate
                                    invoiceId
                                    cashDiscAmount
                                    cashDiscDate
                                    configId
                                    installmentNum
                                    invoiceDate
                                    itemId
                                    offsetAccountNum
                                    offSetAccountType
                                    paymMode
                                    paymSpec
                                    paymTermId
                                    qtyOfInstallments
                                    transTxt
                                }
                                monthlyPayment {
                                    bankAccount {
                                        bankId
                                        bankAgency
                                        bankAgencyDigit
                                        bankAccountNum
                                        bankAccountDigit
                                    }
                                    bankingBillet {
                                        barCode
                                        companyDocument
                                        digitableLine
                                        ourNumber
                                        ourNumberDigit
                                        transactionDate
                                    }
                                    custCard {
                                        processorCode
                                        brand
                                        authorizationNumber
                                        nsu
                                        cv
                                        chargeId
                                        transactionDate
                                        paymentDate
                                        paymId
                                        customerId
                                        recurrent {
                                            cardId
                                            planId
                                            signId
                                        }
                                    }
                                    transAmountCur
                                    dueDate
                                    invoiceId
                                    cashDiscAmount
                                    cashDiscDate
                                    configId
                                    installmentNum
                                    invoiceDate
                                    itemId
                                    offsetAccountNum
                                    offSetAccountType
                                    paymMode
                                    paymSpec
                                    paymTermId
                                    qtyOfInstallments
                                    transTxt
                                }
                            }
                            upSelling {
                                firstInstallment {
                                    bankAccount {
                                        bankId
                                        bankAgency
                                        bankAgencyDigit
                                        bankAccountNum
                                        bankAccountDigit
                                    }
                                    bankingBillet {
                                        barCode
                                        companyDocument
                                        digitableLine
                                        ourNumber
                                        ourNumberDigit
                                        transactionDate
                                    }
                                    custCard {
                                        processorCode
                                        brand
                                        authorizationNumber
                                        nsu
                                        cv
                                        chargeId
                                        transactionDate
                                        paymentDate
                                        paymId
                                        customerId
                                        recurrent {
                                            cardId
                                            planId
                                            signId
                                        }
                                    }
                                    transAmountCur
                                    dueDate
                                    invoiceId
                                    cashDiscAmount
                                    cashDiscDate
                                    configId
                                    installmentNum
                                    invoiceDate
                                    itemId
                                    offsetAccountNum
                                    offSetAccountType
                                    paymMode
                                    paymSpec
                                    paymTermId
                                    qtyOfInstallments
                                    transTxt
                                }
                                monthlyPayment {
                                    bankAccount {
                                        bankId
                                        bankAgency
                                        bankAgencyDigit
                                        bankAccountNum
                                        bankAccountDigit
                                    }
                                    bankingBillet {
                                        barCode
                                        companyDocument
                                        digitableLine
                                        ourNumber
                                        ourNumberDigit
                                        transactionDate
                                    }
                                    custCard {
                                        processorCode
                                        brand
                                        authorizationNumber
                                        nsu
                                        cv
                                        chargeId
                                        transactionDate
                                        paymentDate
                                        paymId
                                        customerId
                                        recurrent {
                                            cardId
                                            planId
                                            signId
                                        }
                                    }
                                    transAmountCur
                                    dueDate
                                    invoiceId
                                    cashDiscAmount
                                    cashDiscDate
                                    configId
                                    installmentNum
                                    invoiceDate
                                    itemId
                                    offsetAccountNum
                                    offSetAccountType
                                    paymMode
                                    paymSpec
                                    paymTermId
                                    qtyOfInstallments
                                    transTxt
                                }
                            }
                            feesEvents {
                                firstInstallment {
                                    bankAccount {
                                        bankId
                                        bankAgency
                                        bankAgencyDigit
                                        bankAccountNum
                                        bankAccountDigit
                                    }
                                    bankingBillet {
                                        barCode
                                        companyDocument
                                        digitableLine
                                        ourNumber
                                        ourNumberDigit
                                        transactionDate
                                    }
                                    custCard {
                                        processorCode
                                        brand
                                        authorizationNumber
                                        nsu
                                        cv
                                        chargeId
                                        transactionDate
                                        paymentDate
                                        paymId
                                        customerId
                                        recurrent {
                                            cardId
                                            planId
                                            signId
                                        }
                                    }
                                    transAmountCur
                                    dueDate
                                    invoiceId
                                    cashDiscAmount
                                    cashDiscDate
                                    configId
                                    installmentNum
                                    invoiceDate
                                    itemId
                                    offsetAccountNum
                                    offSetAccountType
                                    paymMode
                                    paymSpec
                                    paymTermId
                                    qtyOfInstallments
                                    transTxt
                                }
                                monthlyPayment {
                                    bankAccount {
                                        bankId
                                        bankAgency
                                        bankAgencyDigit
                                        bankAccountNum
                                        bankAccountDigit
                                    }
                                    bankingBillet {
                                        barCode
                                        companyDocument
                                        digitableLine
                                        ourNumber
                                        ourNumberDigit
                                        transactionDate
                                    }
                                    custCard {
                                        processorCode
                                        brand
                                        authorizationNumber
                                        nsu
                                        cv
                                        chargeId
                                        transactionDate
                                        paymentDate
                                        paymId
                                        customerId
                                        recurrent {
                                            cardId
                                            planId
                                            signId
                                        }
                                    }
                                    transAmountCur
                                    dueDate
                                    invoiceId
                                    cashDiscAmount
                                    cashDiscDate
                                    configId
                                    installmentNum
                                    invoiceDate
                                    itemId
                                    offsetAccountNum
                                    offSetAccountType
                                    paymMode
                                    paymSpec
                                    paymTermId
                                    qtyOfInstallments
                                    transTxt
                                }
                            }
                        }
                    }
                }
                messages {
                    text
                    typeMessage
                }
                statusCode
            }
        }
    `,
    variables: {
      idMatricula
    }
  }).then(resp => {
    const {
      data: {
        getManutencaoFinanceiraTransacao: {
          value,
          messages,
        }
      },
      errors
    } = resp;

    dispatchGraphError(errors);

    if (messages.length > 0) {
      messages.forEach(message => {
        ExibeMensagem(message.text, message.typeMessage, true);
      });
    }

    return value || [];
  })
  .catch(console.error)
}


/**
* @typedef {Object} SecretariaManutencaoFinanceiraFichaResponse
* @property {string} numeroFatura
* @property {string} item
* @property {string} descricao
* @property {string} configuracao
* @property {string} metodoPagamento
* @property {string} dataDeTransacaoDeValor
* @property {number} valor
* @property {number} descontoBolsa
* @property {number} descontoMetodo
* @property {number} descontoReserva
* @property {number} valorTotalPagar
*/