import {v4 as uuidv4} from "uuid";

export default class Transaction {
  id = uuidv4(); // Removido ao exportar
  /** @param {material|service|upSelling|feesEvents} tipoDeTransacao */
  tipoDeTransacao = "material"; // Removido ao exportar
  /** @param {monthlyPayment|firstInstallment} tipoDeParcela */
  tipoDeParcela = "monthlyPayment"; // Removido ao exportar
  dataAreaId = ""; // Removido ao exportar
  fiscalEstablishmentId = ""; // Removido ao exportar
  matriculaId = ""; // Removido ao exportar
  financialMovType = 1; // Removido ao exportar
  custAccount = ""; // Removido ao exportar
  transStatus = 1; // Removido ao exportar

  bankAccount = null;
  bankingBillet = null;
  custCard = null;
  transAmountCur = 0;
  dueDate = "2023-03-15";
  invoiceId = "";
  cashDiscAmount = 0.0;
  cashDiscDate = "2023-03-15";
  configId = "MENSALIDADE";
  installmentNum = 1;
  invoiceDate = "2023-03-15";
  itemId = "";
  offsetAccountNum = "";
  offSetAccountType = 0;
  paymMode = "ITBol_Sart";
  paymSpec = "";
  paymTermId = "";
  qtyOfInstallments = 1;
  transTxt = "";

  toJSON() {
    const json = {...this};
    delete json.id;
    delete json.tipoDeTransacao;
    delete json.tipoDeParcela;
    return json;
  }
}