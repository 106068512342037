import React, {useEffect, useState} from "react";
import {useMsal, useMsalAuthentication} from "@azure/msal-react";
import {BrowserAuthError, CacheLookupPolicy, InteractionStatus, InteractionType} from "@azure/msal-browser";
import {fetchDataGET} from "../fetch";
import {ApolloClient, ApolloProvider, from, InMemoryCache} from "@apollo/client";
import context from "../context.json";
import {onError} from "@apollo/client/link/error";
import {dispatchGraphError} from "../utils/dispatchGraphError";
import {fetchImageGet} from "../utils/fetchImageGet";
import {BatchHttpLink} from "@apollo/client/link/batch-http";
import {RetryLink} from "@apollo/client/link/retry";
import * as _authService from '../service/auth-service';
import {useRecoilState} from "recoil";
import {permissionsState} from "../recoil/atoms/permissionsState";

const AuthContext = React.createContext({
  listUnidades: [],
  listPermissions: [],
  setListPermissions: () => {},
  setListUnidades: () => {},
  logoUnidade: "",
  setLogoUnidade: () => {},
  setUserImage: () => { },
  appContext: {},
  userImage: "",
});

const AuthProvider = ({ children }) => {
  const scopes = ["3d90cd04-7efd-4151-a764-201e5959e56e/.default"];
  const scopesGraph = ["user.read", "group.read.all"];
  const { result, error, login } = useMsalAuthentication(
    InteractionType.Popup,
    {
      scopes: scopesGraph,
      state: `${window.location.pathname}${window.location.search}`
    }
  );
  const [graphData, setGraphData] = useState(null);
  const [listUnidades, setListUnidades] = useState([]);
  const [listPermissions, setListPermissions] = useRecoilState(permissionsState);
  const [logoUnidade, setLogoUnidade] = useState(null);
  const [client, setClient] = useState(null);
  const [appConfig] = useState(context);
  const { instance, inProgress, accounts } = useMsal();
  const [userImage, setUserImage] = useState(null);

  useEffect( () => {
    const doAuth = async () => {
      if (!!graphData) {
        return
      }
      if (!!error) {
        if (error instanceof BrowserAuthError) {
          login(InteractionType.Redirect, {
            scopes: scopesGraph,
            state: `${window.location.pathname}${window.location.search}`
          })
        }
        console.error(error);
      }

      if (result && inProgress === InteractionStatus.None) {
        sessionStorage.setItem('@SECRETARIA/msalToken', result.accessToken);
        sessionStorage.setItem('@SECRETARIA/nomeUsuarioLogado', result.account.name);
        sessionStorage.setItem('@SECRETARIA/emailUsuarioLogado', result.account.username);

        const fetchGraphData = async () => {
          await fetchDataGET("https://graph.microsoft.com/v1.0/me", result.accessToken)
            .then(data => setGraphData(data))
            .catch(error => console.log(error));
          const object = await fetchImageGet("https://graph.microsoft.com/v1.0/me/photos/48x48/$value", result.accessToken);
          let blob = new Blob([object], { type: "image/jpeg" });
          let newUrl = URL.createObjectURL(blob);
          setUserImage(newUrl);
        }

        const fetchMemberOf = async () => {
          await fetchDataGET("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true&$orderby=displayName&$select=id,displayName,description", result.accessToken)
            .then(data => {
              let gruposSecretaria = data.value?.filter(grupos => grupos.displayName.toLowerCase().indexOf("secretaria") > -1);
              let codigoUnidadeList = gruposSecretaria?.map(({description}) =>  description)
              sessionStorage.setItem('@SECRETARIA/unidadesPermitidas', JSON.stringify(codigoUnidadeList))
              setListUnidades(codigoUnidadeList);
            }).catch(error => console.log(error));
        }

        const signInGraph = async (token) => {
          const {permissions} = await _authService.signIn(token);
          setListPermissions(permissions);
        }

        fetchGraphData();
        fetchMemberOf();

        if (!client) {
          const errorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
              graphQLErrors.forEach(({ message, locations, path }) =>
                console.error(
                  `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
                )
              );
              dispatchGraphError(graphQLErrors, true)
            }
            if (networkError) console.error(`[Network error]: ${networkError}`);
          });

          const accessTokenRequest = {
            scopes,
            account: accounts[0],
            forceRefresh: true,
            cacheLookupPolicy: CacheLookupPolicy.Default
          };

          const resp = await instance.acquireTokenSilent(accessTokenRequest)
          const authorization = `Bearer ${resp.accessToken}`;

          const retryLink = new RetryLink({
            delay: {
              initial: 300,
              max: Infinity,
              jitter: true
            },
            attempts: {
              max: 5,
              retryIf: (error, _operation) => !!error
            }
          });

          const httpLink = new BatchHttpLink({
            uri: `${process.env.REACT_APP_API_URL}`,
            headers: {
              authorization
            },
            fetch: async (uri, options) => {
              const resp = await fetch(uri, options);
              if (resp.status === 401) {
                await instance.acquireTokenRedirect(accessTokenRequest);
              }
              return resp;
            }
          })

          const apolloClient = new ApolloClient({
            link: from([errorLink, httpLink, retryLink]),
            cache: new InMemoryCache({
              addTypename: false,
              resultCacheMaxSize: 0,
              resultCaching: false,
              canonizeResults: false,
            }),
            queryDeduplication: true,
            defaultOptions: {
              watchQuery: {
                initialFetchPolicy: 'no-cache',
                fetchPolicy: 'no-cache',
                refetchWritePolicy: 'overwrite',
                nextFetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
              },
              query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
              },
            },
            assumeImmutableResults: false,
          });
          window.apolloClient = apolloClient;
          window.msalInstance = instance;

          setClient(apolloClient);

          await signInGraph(resp.accessToken);
        }
        
        if (result?.state && result?.state !== `${window.location.pathname}${window.location.search}`) {
          window.location.href = `${window.location.pathname}${window.location.search}`;
        }
      }
    }

    doAuth();
  }, [error, result, graphData, login, client, instance, accounts, inProgress]);

  if (!client)
    return null

  return (
    <ApolloProvider client={client}>
      <AuthContext.Provider value={{
        userImage,
        setUserImage,
        listUnidades,
        setListUnidades,
        listPermissions,
        setListPermissions,
        logoUnidade,
        setLogoUnidade,
        client,
        appContext:
        appConfig
      }}>
        {children}
      </AuthContext.Provider>
    </ApolloProvider>
  )
}
export { AuthContext, AuthProvider };
