import {AppContext} from "../App";

export default function withAppContext(Component) {
  return function _hoc(props) {
    return (
      <AppContext.Consumer>
        {context => <Component _appContext={context} {...props} />}
      </AppContext.Consumer>
    );
  }
}