import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function GetFinanceiro(idMatricula) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            getFinanceiroByMatricula
        },
        errors = []
    } = await client.query({
        query : gql`
            query ListResponsaveisByMatricula($idMatricula: String!) {
                getFinanceiroByMatricula(idMatricula: $idMatricula) {
                    matriculaId
                    parcelas {
                        numeroFatura
                        metodPagamento
                        datavencimento
                        responsavel
                        vencido
                        valorOriginal
                        valorPagar
                        valorPago
                        formaPagamento
                        dataPagamento
                        isAcordo
                        nomeResponsavel
                        detalhes {
                            item
                            faturaId
                            descricao
                            configuracao
                            dataTransacao
                            valor
                            descontoBolsa
                            descontoMetodo
                            descontoReserva
                            valorTotalPagar
                            metodoPagamento
                        }
                    }
                }
            }
        `,
        variables: {
            idMatricula
        }
    })

    dispatchGraphError(errors);

    return getFinanceiroByMatricula;
}

