import {useSetRecoilState} from "recoil";
import {selectedMatriculaState} from "../recoil/atoms/selectedMatriculaState";
import * as _matriculaService from "../service/matricula-service";
import {Matricula} from "../components/MatriculaAutocompleteSelect/MatriculaAutocompleteSelect";
import {selectedMatriculaFullState} from "../recoil/atoms/selectedMatriculaFullState";

export default function useSelectMatricula() {
  const setMatricula = useSetRecoilState(selectedMatriculaState);
  const setSelectedMatriculaFullState = useSetRecoilState(selectedMatriculaFullState);
  
  return {
    selecioneMatricula: (obj: Matricula) => {
      setMatricula(obj);
      if (obj && 'idMatricula' in obj)
        _matriculaService.GetMatricula(obj.idMatricula)
          .then(matricula => {
            setSelectedMatriculaFullState(matricula)
          })
    }
  }
}