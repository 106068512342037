import {blockedResourcesEntitiesValues} from "./blockedResourcesEntities";
import blockedResourcesSystem from "./blockedResourcesSystem";

const blockedResourcesTypes = [
  {
    "action": "MetodosDePagamento/Unidade/Tipo/Entrada/Boleto",
    "description": "MD - Checkout etapa 4 Entrada - Boleto",
    "system": blockedResourcesSystem.SMO,
    "type": blockedResourcesEntitiesValues.Unidade
  },
  {
    "action": "MetodosDePagamento/Unidade/Tipo/Entrada/CartaoDeCredito",
    "description": "MD - Checkout etapa 4 Entrada - Cartão de crédito",
    "system": blockedResourcesSystem.SMO,
    "type": blockedResourcesEntitiesValues.Unidade
  },
  {
    "action": "MetodosDePagamento/Unidade/Tipo/Recorrente/Boleto",
    "description": "MD - Checkout etapa 4 Recorrente - Boleto",
    "system": blockedResourcesSystem.SMO,
    "type": blockedResourcesEntitiesValues.Unidade
  },
  {
    "action": "MetodosDePagamento/Unidade/Tipo/Recorrente/DA",
    "description": "MD - Checkout etapa 4 Recorrente - Débito automático",
    "system": blockedResourcesSystem.SMO,
    "type": blockedResourcesEntitiesValues.Unidade
  },
  {
    "action": "MetodosDePagamento/Unidade/Tipo/Recorrente/CartaoDeCredito",
    "description": "MD - Checkout etapa 4 Recorrente - Cartão de crédito",
    "system": blockedResourcesSystem.SMO,
    "type": blockedResourcesEntitiesValues.Unidade
  },
  {
    "action": "MudancaDeMetodoDePagamento/Unidade/Boleto",
    "description": "MD - Mudança de método - Boleto",
    "system": blockedResourcesSystem.SMO,
    "type": blockedResourcesEntitiesValues.Unidade
  },
  {
    "action": "MudancaDeMetodoDePagamento/Unidade/CartaoDeCreditoRecorrente",
    "description": "MD - Mudança de método - Cartão de crédito recorrente",
    "system": blockedResourcesSystem.SMO,
    "type": blockedResourcesEntitiesValues.Unidade
  },
  {
    "action": "MudancaDeMetodoDePagamento/Unidade/DebitoAutomaticoRecorrente",
    "description": "MD - Mudança de método - Débito automático recorrente",
    "system": blockedResourcesSystem.SMO,
    "type": blockedResourcesEntitiesValues.Unidade
  },
  {
    "action": "Financeiro/SecretariaFinanceiroGet",
    "description": "SW - Aba 'Financeiro' localizada no modal de detalhes da matrícula",
    "system": blockedResourcesSystem.Secretaria,
    "type": blockedResourcesEntitiesValues.Matricula
  },
  {
    "action": "Movimentacao/Unidade/Boleto",
    "description": "SW - Movimentação - Boleto",
    "system": blockedResourcesSystem.Secretaria,
    "type": blockedResourcesEntitiesValues.Unidade
  },
  {
    "action": "Movimentacao/Unidade/DA",
    "description": "SW - Movimentação - DA",
    "system": blockedResourcesSystem.Secretaria,
    "type": blockedResourcesEntitiesValues.Unidade
  },
]

export default blockedResourcesTypes;