import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function TurmasServicoUnidade(idServicoUnidade, options) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            getTurmasServicoUnidade
        },
        error,
        errors
    } = await client.query({
        query: gql`
        query GetTurmasServicoUnidade($id: ID!, $options: TurmaOptions) {
          getTurmasServicoUnidade(id: $id, options: $options) {
            id
            nome
            periodo
            codigoErp
          }
        }
        `,
        variables: {
            id: idServicoUnidade,
            options
        }
    })
    if (error) {
        alert("Erro ao listar matriculas.");
    }

    dispatchGraphError(errors);

    return getTurmasServicoUnidade;
}

export async function AtualizarTurma(idMatricula, idNovaTurma) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            atualizarTurma
        },
        error,
        errors
    } = await client.mutate({
        mutation: gql`
        mutation AtualizarTurma($idMatricula: String, $idNovaTurma: String) {
          atualizarTurma(idMatricula: $idMatricula, idNovaTurma: $idNovaTurma) {
            id
          }
        }
        `,
        variables: {
            idMatricula,
            idNovaTurma
        }
    })
    if (error) {
        alert("Erro ao listar matriculas.");
    }

    dispatchGraphError(errors);

    return atualizarTurma;
}
