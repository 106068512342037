import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const csatViewedListState = atom<string[]>({
  key: 'csatViewedListState',
  default: [],
  effects: [
    persistAtomEffect
  ]
});
