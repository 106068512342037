import React, {FC, useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  ArrowBackTwoTone,
  CameraAltTwoTone,
  CloseTwoTone,
  DeleteTwoTone,
  QrCodeTwoTone,
  SaveTwoTone
} from "@mui/icons-material";
import {styled} from "@mui/system";
import {BlobProperties, ImageQualityReport, ImageQualityReportScore} from "seb-graph-api-types/generated";
import {Grid, Grow} from "@mui/material";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import QRCode from "qrcode";
import ImageQualityReportList from "./ImageQualityReportList";
import {ExibeMensagem} from "../shared/Message";
import {useRecoilValue} from "recoil";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import {selectedUnidadeState} from "../../recoil/atoms/selectedUnidadeState";
import BlobToBase64 from "../../utils/blobToBase64";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material/styles/createTheme";
import {gql, useMutation} from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import {useTheme} from "@mui/material/styles";
import {Camera} from "react-camera-pro";

const MUTATE_FACE_ANALYSER = gql`
    mutation FaceAnalyser($imageBase64: String) {
        faceAnalyser(imageBase64: $imageBase64) {
            success
            data {
                faceImageData
                imageQualityReport {
                    pitch
                    roll
                    yaw
                    blur
                    overexposure
                    underexposure
                    noise
                    resolution
                    glasses
                    eyeOcclusion
                    mouthOcclusion
                    foreheadOcclusion
                    finalScore
                }
            }
        }
    }
`

const StyleDialog = styled(Dialog)((({theme}) => ({
  "& .MuiPaper-root": {
    overflowY: "visible",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",
      height: "100%",
      maxHeight: "100%",
      borderRadius: 0,
      "& .MuiDialogContent-root": {
        padding: "16px 18px"
      }
    }
  },
})));

export const ModalUploadFacialImage: FC<{
  contatoId?: string
  title?: string
  url?: any
  blobProps?: BlobProperties
  finalScore?: ImageQualityReportScore
  onUpload?: (event: React.ChangeEvent<any>, base64: string) => void
  loading?: boolean
  onClose?: () => void
  showStats?: boolean
  showWebcam?: boolean
  open?: boolean
}> = (props) => {
  const {
    onClose,
    title,
    url,
    contatoId,
    finalScore,
    blobProps,
    loading,
    onUpload,
    showWebcam: showWC,
    open = false,
    showStats,
    ...other
  } = props;
  const theme = useTheme();
  const webcamRef = React.useRef(null);
  const [base64, setBase64] = useState<string | null>(null);
  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [selectedCameraId, setSelectedCameraId] = useState<string>(null);
  const [showWebcam, setShowWebcam] = useState(showWC);
  const inputRef = useRef<HTMLInputElement>(null);
  const [cameras, setCameras] = useState<{ value: string; text: string }[]>([]);
  const matricula = useRecoilValue(selectedMatriculaState);
  const unidade = useRecoilValue(selectedUnidadeState);
  const [mutateFaceAnalyser, {loading: loadingAnalyser, client}] = useMutation(MUTATE_FACE_ANALYSER);
  const [previewStats, setPreviewStats] = useState<ImageQualityReport | null>(null);
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const capture = React.useCallback(async () => {
    const imageBase64 = webcamRef.current.takePhoto();
    setBase64(imageBase64)
    mutateFaceAnalyser({variables: {imageBase64}})
      .then(({data}) => {
        const {faceAnalyser} = data || {};
        setBase64(`data:image/jpeg;base64,${faceAnalyser?.data?.faceImageData}`)
        setPreviewStats(faceAnalyser?.data?.imageQualityReport)
      })
      .catch((error) => {
        console.error(error)
      })
    
  }, [webcamRef]);
  
  useEffect(() => {
    if (showWC && !showWebcam) {
      handleToggleWebCam();
      setShowWebcam(showWC);
    }
  }, [showWC]);
  
  const handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.item(0)
    if (file) {
      const encoded = await BlobToBase64(file);
      setBase64(encoded)
      handleClose()
      if (onUpload) onUpload(event, encoded)
    }
  }
  
  const handleClickUpload = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }
  
  const handleToggleWebCam = () => {
    if (!showWebcam) {
      navigator.mediaDevices.enumerateDevices().then(function (devices) {
        let options: { value: string; text: string }[] = [];
        for (let i = 0; i < devices.length; i++) {
          const device = devices[i];
          if (device.kind === 'videoinput') {
            options.push({
              value: device.deviceId,
              text: device.label || 'camera ' + (i + 1)
            })
          }
        }
        setCameras(options);
        setBase64(null);
        if (options.length)
          setSelectedCameraId(options[0].value);
      })
    }
    setShowWebcam(old => !old)
  }
  
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    resetState()
  }
  
  const resetState = () => {
    setShowWebcam(false)
    setBase64(null)
    setQrCodeImage(null)
    setPreviewStats(null)
  }
  
  const handleChangeCamera = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedCameraId(event.target.value);
  }
  
  const handleClickCreateQRCode = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("matriculaId", matricula.idMatricula)
    url.searchParams.set("unidadeId", unidade.id)
    url.searchParams.set("contatoId", contatoId)
    url.searchParams.set("openUploadModal", "true")
    navigator.clipboard.writeText(url.toString()).then(() => {
      ExibeMensagem("Link do QRCode copiado para a área de transferência!", "0", true);
    });
    QRCode.toDataURL(url.toString(), function (_, url: string) {
      setQrCodeImage(url)
    })
  }
  
  const handleClickUseWebcamImage = event => {
    setShowWebcam(false)
    setBase64(null)
    setPreviewStats(null)
    handleClose()
    if (onUpload) onUpload(event, base64)
  }
  
  const handleClickRemoveWebcamImage = () => {
    setBase64(null)
    setPreviewStats(null)
  }
  
  const newIcon = () => (
    <svg
      viewBox="0 0 24 24"
      width={24}
      height={24}
    >
      <path
        d="M19,1L17.74,3.75L15,5L17.74,6.26L19,9L20.25,6.26L23,5L20.25,3.75M9,4L6.5,9.5L1,12L6.5,14.5L9,20L11.5,14.5L17,12L11.5,9.5M19,15L17.74,17.74L15,19L17.74,20.25L19,23L20.25,20.25L23,19L20.25,17.74"
        fill="yellow"></path>
    </svg>
  )
  
  return (
    <StyleDialog
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={open}
      {...other}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          px: 3,
          py: 2
        }}
      >
        {showWebcam ? (
          <Stack
            direction="row"
            alignItems="center"
          >
            <Button
              variant="text"
              color="inherit"
              size="small"
              sx={{
                width: 30
              }}
              onClick={resetState}
            >
              <ArrowBackTwoTone/>
            </Button>
            <Typography variant="h6">
              Câmera
            </Typography>
          </Stack>
        ) : (
          <Typography variant="h6">
            {title}
          </Typography>
        )}
        
        <IconButton
          color="inherit"
          onClick={handleClose}
          sx={{
            width: "auto",
          }}
        >
          <CloseTwoTone/>
        </IconButton>
      </Stack>
      <DialogContent
        sx={{
          position: "relative",
          height: showWebcam ? smDown ? "100%" : 600 : undefined,
          width: showWebcam ? smDown ? "100%" : undefined : undefined,
        }}
      >
        <Grid
          container
          spacing={4}
          direction={smDown ? "column" : "row"}
          sx={{
            [theme.breakpoints.down("sm")]: {
              justifyContent: "center"
            }
          }}
        >
          <Grid
            item
            sm={showWebcam ? 12 : 8}
          >
            {showWebcam ? (
              <>
                <Stack
                  direction={smDown ? "column" : "row"}
                  justifyContent="space-around"
                >
                  {((smDown && base64 == null) || (!smDown)) && (
                    <Grow
                      in
                      easing="ease-out"
                      mountOnEnter
                      unmountOnExit
                    >
                      <Stack
                        direction="column"
                        spacing={2}
                        justifyContent={showWebcam ? "center" : undefined}
                        alignItems="center"
                        sx={{
                          display: base64 ? "none" : undefined,
                        }}
                      >
                        <Camera
                          numberOfCamerasCallback={setNumberOfCameras}
                          ref={webcamRef}
                          errorMessages={{
                            noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
                            permissionDenied: 'Permission denied. Please refresh and give camera permission.',
                            switchCamera:
                              'It is not possible to switch camera to different one because there is only one video device accessible.',
                            canvas: 'Canvas is not supported.'
                          }}
                        />
                        <Stack
                          direction="row"
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            height: 80,
                            background: "rgb(41 38 38 / 21%)"
                          }}
                        >
                          <Button
                            variant="text"
                            color="primary"
                            size={smDown ? "large" : "small"}
                            hidden={numberOfCameras <= 1}
                            disabled
                            sx={{
                              opacity: 0
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={42}
                              height={42}
                              fill="white"
                            >
                              <path
                                d="M20 5H17L15 3H9L7 5H4C2.9 5 2 5.9 2 7V19C2 20.11 2.9 21 4 21H20C21.11 21 22 20.11 22 19V7C22 5.9 21.11 5 20 5M5 12H7.1C7.65 9.29 10.29 7.55 13 8.1C13.76 8.25 14.43 8.59 15 9L13.56 10.45C13.11 10.17 12.58 10 12 10C10.74 10 9.6 10.8 9.18 12H11L8 15L5 12M16.91 14C16.36 16.71 13.72 18.45 11 17.9C10.25 17.74 9.58 17.41 9 17L10.44 15.55C10.9 15.83 11.43 16 12 16C13.27 16 14.41 15.2 14.83 14H13L16 11L19 14H16.91Z"/>
                            </svg>
                          </Button>
                          
                          <Button
                            variant="text"
                            color="primary"
                            size={smDown ? "large" : "small"}
                            onClick={capture}
                            disabled={loadingAnalyser}
                          >
                            {loadingAnalyser ? <CircularProgress color="primary" size={20}/> :
                              (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={64}
                                  height={64}
                                  fill="white"
                                >
                                  <path
                                    d="M12 5C15.87 5 19 8.13 19 12C19 15.87 15.87 19 12 19C8.13 19 5 15.87 5 12C5 8.13 8.13 5 12 5M12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4Z"/>
                                </svg>
                              )}
                          </Button>
                          
                          <Button
                            variant="text"
                            color="primary"
                            size={smDown ? "large" : "small"}
                            disabled={loadingAnalyser}
                            hidden={numberOfCameras <= 1}
                            onClick={() => {
                              webcamRef.current.switchCamera();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={42}
                              height={42}
                              fill="white"
                            >
                              <path
                                d="M20 5H17L15 3H9L7 5H4C2.9 5 2 5.9 2 7V19C2 20.11 2.9 21 4 21H20C21.11 21 22 20.11 22 19V7C22 5.9 21.11 5 20 5M5 12H7.1C7.65 9.29 10.29 7.55 13 8.1C13.76 8.25 14.43 8.59 15 9L13.56 10.45C13.11 10.17 12.58 10 12 10C10.74 10 9.6 10.8 9.18 12H11L8 15L5 12M16.91 14C16.36 16.71 13.72 18.45 11 17.9C10.25 17.74 9.58 17.41 9 17L10.44 15.55C10.9 15.83 11.43 16 12 16C13.27 16 14.41 15.2 14.83 14H13L16 11L19 14H16.91Z"/>
                            </svg>
                          </Button>
                        </Stack>
                      </Stack>
                    </Grow>
                  )}
                  
                  {base64 && (
                    <>
                      <Grow
                        in
                        easing="ease-out"
                        mountOnEnter
                        unmountOnExit
                      >
                        <Stack
                          spacing={2}
                        >
                          <Stack
                            justifyContent="center"
                            spacing={2}
                            direction={smDown ? "column" : "row"}
                            alignItems={smDown ? "center" : undefined}
                          >
                            <ProfilePicture
                              url={base64}
                              placeholder={title}
                              icon={null}
                              height={smDown ? 400 : 300}
                              width={smDown ? 300 : 200}
                              disableHover
                              badgeIcon={(previewStats !== null && !loadingAnalyser) ? newIcon() : undefined}
                              badgeTitle="Analisado e recortado com IA"
                            />
                            
                            {previewStats !== null &&
															<ImageQualityReportList
																imageQualityReport={previewStats}
																loading={loadingAnalyser}
																horizontal={smDown}
																sx={{
                                  mt: title ? 5 : 0,
                                }}
															/>
                            }
                          </Stack>
                          
                          <Stack
                            direction="row"
                            spacing={1}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleClickUseWebcamImage}
                              startIcon={loadingAnalyser ? <CircularProgress color="primary" size={20}/> :
                                <SaveTwoTone/>}
                              disabled={loadingAnalyser || (previewStats?.finalScore === "BAD" || !previewStats)}
                            >
                              {loadingAnalyser ? "Analisando..." : (previewStats?.finalScore === "BAD" || !previewStats) ? "Tire outra foto!" : "Utilizar"}
                            </Button>
                            
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={handleClickRemoveWebcamImage}
                              disabled={loadingAnalyser}
                              sx={{
                                width: 30
                              }}
                            >
                              <SvgIcon><DeleteTwoTone/></SvgIcon>
                            </Button>
                          </Stack>
                        </Stack>
                      </Grow>
                    </>
                  )}
                </Stack>
              </>
            ) : (
              <Stack
                spacing={2}
                direction={smDown ? "column" : "row"}
                alignItems={smDown ? "center" : undefined}
                justifyContent={showWebcam || smDown ? "center" : undefined}
              >
                <ProfilePicture
                  url={url}
                  placeholder={title}
                  icon={null}
                  height={smDown ? 300 : 400}
                  width={smDown ? 200 : 300}
                  loading={loading}
                  disableHover
                />
                {(showStats && blobProps?.metadata?.analysisDecoded?.imageQualityReport) &&
									<ImageQualityReportList
										imageQualityReport={blobProps?.metadata?.analysisDecoded?.imageQualityReport}
										horizontal={smDown}
										sx={{
                      mt: title ? 5 : 0,
                    }}
									/>
                }
                
                <Input
                  inputRef={inputRef}
                  type="file"
                  onChange={handleFileSelection}
                  sx={{
                    display: "none"
                  }}
                  inputProps={{
                    accept: "image/png, image/jpeg"
                  }}
                />
              </Stack>
            )}
          </Grid>
          {!showWebcam && (
            <Grid
              item
              sm={4}
            >
              <Stack spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SvgIcon><CameraAltTwoTone/></SvgIcon>}
                  onClick={handleToggleWebCam}
                >
                  Capturar pela Webcam
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickUpload}
                  startIcon={<SvgIcon><AttachFileIcon/></SvgIcon>}
                >
                  Enviar anexo...
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  disabled={!!qrCodeImage}
                  onClick={handleClickCreateQRCode}
                  startIcon={(
                    <SvgIcon>
                      <QrCodeTwoTone/>
                    </SvgIcon>
                  )}
                >
                  Enviar pelo telefone
                </Button>
                
                {qrCodeImage && (
                  <Box
                    sx={{
                      pt: 3
                    }}
                  >
                    <ProfilePicture
                      url={qrCodeImage}
                      placeholder=""
                      height={200}
                      icon={null}
                      width={200}
                      disableHover={true}
                    />
                  </Box>
                )}
              </Stack>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </StyleDialog>
  );
};

ModalUploadFacialImage.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
