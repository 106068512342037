import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export interface Permission {
  id: string
  permissionName: string
  permissionId: string
  systemId: string
  systemName: string
  roleName: string
}

export const permissionsState = atom<Permission[]>({
  key: 'permissionsState',
  default: [],
  effects: [
    persistAtomEffect
  ]
});
