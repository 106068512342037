import {ColorPreset} from "../theme";

export default function getAmbiente(): ColorPreset {
  switch (window.location.hostname) {
    case 'localhost':
      return 'localhost';
    case 'dev.secretariav2.seb.com.br':
      return 'development';
    case 'hml.secretariav2.seb.com.br':
      return 'homolog';
    default:
      return 'production';
  }
}
