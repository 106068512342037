export default class Transactions {
  service = {
    firstInstallment: [],
    monthlyPayment: [],
  };
  material = {
    firstInstallment: [],
    monthlyPayment: [],
  };
  upSelling = {
    firstInstallment: [],
    monthlyPayment: [],
  };
  feesEvents = {
    firstInstallment: [],
    monthlyPayment: [],
  };
  
  /** @param {Transaction} transaction */
  addTransaction(transaction) {
    const tipoDeTransacao = transaction.tipoDeTransacao;
    const tipoDeParcela = transaction.tipoDeParcela;
    const newTransaction = {...transaction};
    delete newTransaction.tipoDeTransacao;
    delete newTransaction.tipoDeParcela;
    delete newTransaction.id;
    delete newTransaction.dataAreaId;
    delete newTransaction.fiscalEstablishmentId;
    delete newTransaction.matriculaId;
    delete newTransaction.financialMovType;
    delete newTransaction.custAccount;
    delete newTransaction.transStatus;
    delete newTransaction.BankAccount;
    delete newTransaction.BankingBillet;
    delete newTransaction.CustCard;
    this[tipoDeTransacao][tipoDeParcela].push(newTransaction);
  }
}