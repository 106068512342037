import React, {FC, useMemo} from "react";
import Stack from "@mui/material/Stack";
import {ImageQualityReport, ImageQualityReportScore} from "seb-graph-api-types/generated";
import reportCategories from "../AnexoFoto/reportCategories";
import {Grow, Skeleton, SxProps, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import colors from "./colors";
import {Theme} from "@mui/material/styles/createTheme";

const renderIcon = (reports: ImageQualityReport) => (key: string, index: number) => {
  const score: ImageQualityReportScore = reports[key]
  if (!score) return null
  
  const category = reportCategories[key]
  
  if (!category) return null
  
  const message = category.message[score] ?? ""
  const image = category.image[score] ?? null
  
  if (!image) return null;
  
  return (
    <Grow
      key={index}
      in
      style={{transformOrigin: '0 0 0'}}
      {...(index !== 0 ? {timeout: 1000} : {})}
    >
      <Tooltip
        title={message}
      >
        <Box
          sx={{
            backgroundColor: colors(score),
            color: 'white',
            width: '34px',
            height: '34px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {image()}
        </Box>
      </Tooltip>
    </Grow>
  )
}


const ImageQualityReportList: FC<{
  imageQualityReport: ImageQualityReport
  sx?: SxProps<Theme>
  loading?: boolean
  horizontal?: boolean
}> = (
  {
    imageQualityReport,
    sx,
    loading,
    horizontal
  }
) => {
  const imageQualityReportKeys = useMemo(
    () => Object.keys(imageQualityReport),
    [imageQualityReport]
  );
  
  if (loading)
    return (
      <Stack
        spacing={1}
        useFlexGap
        flexWrap="wrap"
        alignItems="center"
        sx={{
          maxHeight: horizontal ? 100 : 300,
          width: horizontal ? "100%" : 100,
          mt: 0,
          ml: 2,
          ...sx
        }}
      >
        {Array.from(Array(imageQualityReportKeys?.length ?? 12), (_, x) => x + 1).map((index) => (
          <Grow
            key={index}
            in
            style={{transformOrigin: '0 0 0'}}
            {...(index !== 0 ? {timeout: 1000} : {})}
          >
            <Skeleton
              animation="wave"
              width={34}
              height={34}
              sx={{
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                transform: 'none',
              }}
            />
          </Grow>
        ))}
      </Stack>
    )
  
  return (
    <Stack
      spacing={1}
      useFlexGap
      flexWrap="wrap"
      alignItems="center"
      sx={{
        maxHeight: horizontal ? 100 : 300,
        width: horizontal ? "100%" : 100,
        mt: 0,
        ml: 2,
        ...sx
      }}
    >
      {imageQualityReportKeys.map(renderIcon(imageQualityReport))}
    </Stack>
  )
}

export default ImageQualityReportList;