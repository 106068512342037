import React from "react";
//@ts-ignore
import {Anexo, ImageQualityReport, Maybe} from "seb-graph-api-types/generated";

interface Props {
  title?: string
  value?: Maybe<Anexo>
  onClick?: () => void
  onUpload?: (event: React.ChangeEvent<HTMLInputElement>, base64: string) => void
  onRestore?: () => void
  onRemove?: () => void
  disabled?: boolean
  loading?: boolean
  width?: number
  height?: number
}

interface Category {
  message: {
    GOOD: string
    ACCEPTABLE: string
    BAD: string
  },
  image: {
    GOOD: () => JSX.Element
    ACCEPTABLE: () => JSX.Element
    BAD: () => JSX.Element
  }
}

const reportCategories: { [k: string]: Category } = {
  pitch: {
    message: {
      GOOD: "Rosto alinhado",
      ACCEPTABLE: "Rosto levemente desalinhado",
      BAD: "Rosto muito desalinhado!",
    },
    image: {
      GOOD: () => (
        <img
          src="/img/man_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      ACCEPTABLE: () => (
        <img
          src="/img/man_white.png"
          width={28}
          height={28}
          style={{
            rotate: "20deg"
          }}
          alt=""
        />
      ),
      BAD: () => (
        <img
          src="/img/man_white.png"
          width={28}
          height={28}
          style={{
            rotate: "45deg"
          }}
          alt=""
        />
      ),
    }
  },
  roll: {
    message: {
      GOOD: "Bom ângulo do rosto",
      ACCEPTABLE: "Rosto levemente inclidado",
      BAD: "Rosto muito inclinado",
    },
    image: {
      GOOD: () => (
        <img
          src="/img/head_right.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      ACCEPTABLE: () => (
        <img
          src="/img/head_right.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      BAD: () => (
        <img
          src="/img/head_right.png"
          width={28}
          height={28}
          alt=""
        />
      ),
    }
  },
  glasses: {
    message: {
      GOOD: "Nenhum óculos",
      ACCEPTABLE: "Óculos obstruindo levemente os olhos",
      BAD: "Óculos obstruindo os olhos!",
    },
    image: {
      GOOD: () => (
        <img
          src="/img/sun-glasses_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      ACCEPTABLE: () => (
        <img
          src="/img/sun-glasses_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      BAD: () => (
        <img
          src="/img/sun-glasses_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
    }
  },
  yaw: {
    message: {
      GOOD: "Ângulo do rosto",
      ACCEPTABLE: "Rosto levemente inclidado",
      BAD: "Rosto muito inclinado",
    },
    image: {
      GOOD: () => (
        <img
          src="/img/down-arrow_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      ACCEPTABLE: () => (
        <img
          src="/img/down-arrow_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      BAD: () => (
        <img
          src="/img/down-arrow_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
    }
  },
  blur: {
    message: {
      GOOD: "Imagem sem desfoque",
      ACCEPTABLE: "Imagem levemente desfocada",
      BAD: "Imagem muito desfocada!",
    },
    image: {
      GOOD: () => (
        <img
          src="/img/blur_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      ACCEPTABLE: () => (
        <img
          src="/img/blur_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      BAD: () => (
        <img
          src="/img/blur_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
    }
  },
  overexposure: {
    message: {
      GOOD: "Boa iluminação",
      ACCEPTABLE: "Imagem com leve excesso de iluminação",
      BAD: "Imagem muito iluminada!",
    },
    image: {
      GOOD: () => (
        <img
          src="/img/sun_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      ACCEPTABLE: () => (
        <img
          src="/img/sun_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      BAD: () => (
        <img
          src="/img/sun_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
    }
  },
  underexposure: {
    message: {
      GOOD: "Boa iluminação",
      ACCEPTABLE: "Imagem levemente escura",
      BAD: "Imagem com pouca iluminação!",
    },
    image: {
      GOOD: () => (
        <img
          src="/img/dark-mode_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      ACCEPTABLE: () => (
        <img
          src="/img/dark-mode_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      BAD: () => (
        <img
          src="/img/dark-mode_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
    }
  },
  noise: {
    message: {
      GOOD: "Imagem sem ruído",
      ACCEPTABLE: "Imagem com leve ruído",
      BAD: "Imagem com muito ruído!",
    },
    image: {
      GOOD: () => (
        <img
          src="/img/dust_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      ACCEPTABLE: () => (
        <img
          src="/img/dust_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      BAD: () => (
        <img
          src="/img/dust_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
    }
  },
  resolution: {
    message: {
      GOOD: "Bom tamanho de imagem",
      ACCEPTABLE: "Imagem com tamanho aceitável",
      BAD: "Imagem muito pequena!",
    },
    image: {
      GOOD: () => (
        <img
          src="/img/minimize_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      ACCEPTABLE: () => (
        <img
          src="/img/minimize_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
      BAD: () => (
        <img
          src="/img/minimize_white.png"
          width={28}
          height={28}
          alt=""
        />
      ),
    }
  },
  eyeOcclusion: {
    message: {
      GOOD: "Olhos visíveis",
      ACCEPTABLE: "Olhos com leve obstrução",
      BAD: "Olhos obstruídos!",
    },
    image: {
      GOOD: () => (
        <img
          src="/img/eye-patch_white.png"
          width={16}
          height={16}
          alt=""
        />
      ),
      ACCEPTABLE: () => (
        <img
          src="/img/eye-patch_white.png"
          width={16}
          height={16}
          alt=""
        />
      ),
      BAD: () => (
        <img
          src="/img/eye-patch_white.png"
          width={16}
          height={16}
          alt=""
        />
      ),
    }
  },
  mouthOcclusion: {
    message: {
      GOOD: "Boca visível",
      ACCEPTABLE: "Boca levemente obstruída",
      BAD: "Boca obstruída!",
    },
    image: {
      GOOD: () => (
        <img
          src="/img/mask_white.png"
          width={16}
          height={16}
          alt=""
        />
      ),
      ACCEPTABLE: () => (
        <img
          src="/img/mask_white.png"
          width={16}
          height={16}
          alt=""
        />
      ),
      BAD: () => (
        <img
          src="/img/mask_white.png"
          width={16}
          height={16}
          alt=""
        />
      ),
    }
  },
  foreheadOcclusion: {
    message: {
      GOOD: "Cabeça visível",
      ACCEPTABLE: "Cabeça levemente obstruída",
      BAD: "Cabeça muito obstruída!",
    },
    image: {
      GOOD: () => (
        <img
          src="/img/hairstyle_white.png"
          width={16}
          height={16}
          alt=""
        />
      ),
      ACCEPTABLE: () => (
        <img
          src="/img/hairstyle_white.png"
          width={16}
          height={16}
          alt=""
        />
      ),
      BAD: () => (
        <img
          src="/img/hairstyle_white.png"
          width={16}
          height={16}
          alt=""
        />
      ),
    }
  }
}

export default reportCategories;