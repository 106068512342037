export default async function BlobToBase64(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      //@ts-ignore
      resolve(reader.result)
    }
    reader.onerror = reject
  })
}