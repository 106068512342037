import React, {Component} from "react";
import {Button, Nav, NavDropdown, Spinner, Table} from "react-bootstrap";
import * as _blackListService from "../../service/blacklist-service";
import BlockedResourcesTypes from "../../utils/blockedResourcesTypes";
import * as formValidacao from "../../Helpers/form-validacao";
import {ModalBase} from "../../components/shared/ModalBase";
import * as Message from "../../components/shared/Message";
import Swal from 'sweetalert2';
import withAppContext from "../../hocs/withAppContext";
import "./styles.css";
import BlockedResourcesEntities from "../../utils/blockedResourcesEntities";
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import blockedResourcesSystem, {blockedResourcesSystemNames} from "../../utils/blockedResourcesSystem";
import {useRecoilValue} from "recoil";
import {unidadesState} from "../../recoil/atoms/unidadesState";
import {matriculasState} from "../../recoil/atoms/matriculasState";

class BlockedResources extends Component {
  constructor(props) {
    super(props);

    this.state = {
      client_id: undefined,
      exibeModal: false,
      isLoading: false,
      idMatricula: "",
      mensagemBloqueio: "Esta tela está em manutenção.\nRetornaremos em breve.",
      action: "",
      errors: {},
      rows: [],
      selectedRow: {},
      camposBusca: {
        search: "",
        action: "",
      },
    };
  }

  async componentDidMount() {
    await this.GetBlockedResources();
  }

  async GetBlockedResources() {
    const {
      _appContext: {setBlockedResources},
    } = this.props;
    this.setState({
      isLoading: true
    })
    return new Promise(async (resolve) => {
      const result = this.state.client_id 
        ? await _blackListService.GetList(this.state.client_id)
        : await _blackListService.GetAll();
      if (result) {
        if (setBlockedResources) {
          setBlockedResources(result);
        }
        this.setState({
          rows: result,
          isLoading: false
        }, () => resolve());
      }
    })
  }

  handleRowSelection = (row, checked) => {
    if (checked) {
      this.setState({
        selectedRow: row
      })
    } else {
      this.setState({
        selectedRow: {}
      })
    }
  }

  OnChange(event) {
    const campos = this.state.camposBusca;
    campos[event.target.name] = event.target.value;

    this.setState({campos: campos});
  }
  handleEdit = row => () => {
    this.setState({
      exibeModal: true,
      selectedRow: row
    })
  }

  handleAdd = () => {
    this.props.history.push("/integracao/bloqueio-de-recursos/novo");
  }

  handleCloseModal = () => {
    this.setState({
      exibeModal: false
    })
  }

  handleSaveBlockedResource = async () => {
    const {
      selectedRow,
      idMatricula,
      mensagemBloqueio
    } = this.state;
    
    this.setState({
      isLoading: true
    })
    
    await _blackListService.Create({
      "sistemaId": `${process.env.REACT_APP_CLIENT_ID}`,
      "codigoMatriculaCrm": String(idMatricula).trim(),
      "action": selectedRow.action || BlockedResourcesTypes[0].action,
      "mensagemBloqueio": mensagemBloqueio,
    })
      .catch(() => {
        Message.ExibeMensagem("Erro ao criar", "2", true);
      })
      .finally(async () => {
        this.setState({
          isLoading: false,
          exibeModal: false,
          selectedRow: {},
        })

        await this.GetBlockedResources();
      })
  }

  handleDeleteRow = async () => {
    const {
      selectedRow,
    } = this.state;
    
    Swal.fire({
      title: `Tem ceteza que deseja apagar "${selectedRow.action}"`,
      text: "Esta operação não é reversível!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, apagar!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.setState({
          isLoading: true,
        })

        await _blackListService.Delete(selectedRow.id);

        this.setState({
          isLoading: false,
          selectedRow: {},
        })

        await this.GetBlockedResources();
        
        Swal.fire(
          'Apagado!',
          `O Recurso "${selectedRow.action} foi removido!"`,
          'success'
        )
      }
    })
  }
  
  searchFilter = (r) => {
    const {
      camposBusca: {
        search,
      }
    } = this.state;
    const s = search.toLocaleLowerCase().trim();
    const sameIdMatricula = String(r.idMatricula).toLocaleLowerCase().trim().includes(s);
    const sameCodigoMatriculaCrm = String(r.codigoMatriculaCrm).toLocaleLowerCase().trim().includes(s);
    return sameIdMatricula || sameCodigoMatriculaCrm;
  }
  
  handleSystemChange = (sistemaId) => {
    this.setState({
      client_id: sistemaId
    }, async () => {
      await this.GetBlockedResources();
    })
  }
  
  getUnidadeColumn = (row) => {
    const {
      unidades
    } = this.props;
    
    const unidade = unidades.find(u => u.id === row.unidadeId);
    
    if (!unidade) return "-";
    
    return `${unidade.nome} (${unidade.codigoERP})`;
  }
  
  getMatriculaColumn = (row) => {
    const {
      matriculas
    } = this.props;
    
    const matricula = matriculas.find(m => m.idMatricula === row.matriculaId);
    
    if (!matricula) return "-";
    
    return `${matricula.estudante_Name} (${matricula.codigoMatricula})`;
  }
  
  render() {
    const {
      selectedRow,
      rows = [],
    } = this.state;
    
    return (
      <div className="panel seb-secretaria-blocked-resources-page-container">
        <h2 className="tituloCss">Bloqueio de Recursos</h2>
        <div className="panel-body p-25">
          <div className="col-md-12 col-lg-12">
            <div className="filtros">
              <div className="row mb-2">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Pesquisar Matrícula CRM</label>
                    <input
                      className={
                        "form-control " +
                        formValidacao.cssTexto(
                          this.state.errors["search"],
                          this.state.camposBusca.search,
                          3
                        )
                      }
                      name="search"
                      value={this.state.camposBusca.search}
                      onChange={(e) => this.OnChange(e)}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>Sistema</label>
                    <Nav className='me-auto'>
                      <NavDropdown
                        className="ddlFundoBrancoSemBorda form-control"
                        value={this.state.client_id || 'Todos'}
                        title={blockedResourcesSystemNames[this.state.client_id] || 'Todos'}
                      >
                        <NavDropdown.Item
                          onClick={() => this.handleSystemChange(undefined)}
                          className="cssScroll dropDownItemSemBorda"
                        >
                          Todos
                        </NavDropdown.Item>
                        {Object.values(blockedResourcesSystem).map((resource, idx) => (
                          <NavDropdown.Item
                            key={idx}
                            onClick={() => this.handleSystemChange(resource)}
                            className="cssScroll dropDownItemSemBorda"
                          >
                            {blockedResourcesSystemNames[resource]}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    </Nav>
                  </div>
                </div>

                <div className="col-md-1 mb-3">
                  <Button
                    type="button"
                    variant="success"
                    disabled={this.state.isLoading}
                    style={{marginTop: 15}}
                    onClick={this.handleAdd}
                  >
                    {this.state.isLoading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                      >
                        <span className="visually-hidden">Carregando...</span>
                      </Spinner>
                    ) : "Novo"}
                  </Button>
                </div>
              </div>
            </div>

            <Table className="tableStyle" bordered responsive style={{backgroundColor: "#f5f5f5"}}>
              <thead>
              <tr>
                <th>#</th>
                <th>Sistema</th>
                <th>Tipo</th>
                <th>Recurso</th>
                <th>Descrição</th>
                <th>Matricula</th>
                <th>Unidade</th>
                <th>Ações</th>
              </tr>
              </thead>
              <tbody>
              {rows.filter(this.searchFilter).map((row) => {
                const type = BlockedResourcesTypes.find(r => r.action === row.action)
                return (
                  <tr
                    key={row.id}
                    className={selectedRow.id === row.id ? "trSelected" : ""}
                    onClick={event => this.handleRowSelection(row, true)}
                  >
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectedRow.id === row.id}
                        onChange={event => this.handleRowSelection(row, event.target.checked)}
                      />
                    </td>
                    <td>{blockedResourcesSystemNames[row.sistemaId]}</td>
                    <td>{BlockedResourcesEntities.find(t => t.value === row.type)?.label ?? ""}</td>
                    <td>{row.action}</td>
                    <td>{type?.description || ""}</td>
                    <td>{this.getMatriculaColumn(row)}</td>
                    <td>{this.getUnidadeColumn(row)}</td>
                    <td>
                      {selectedRow.id === row.id && (
                        <Button
                          type="button"
                          variant="danger"
                          style={{marginRight: 5}}
                          onClick={this.handleDeleteRow}
                        >
                          Remover
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </Table>


            <ModalBase
              style={"max-Width: 850"}
              show={this.state.exibeModal}
              Fechar={this.handleCloseModal}
              titulo={"id" in selectedRow ? `Editar "${selectedRow.action}"` : "Adicionar Bloqueio"}
            >
              <div className="panel">
                <div className="panel-body">
                  <div className="row mb-2">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Matrícula CRM</label>
                        <input
                          className="form-control"
                          value={this.state.idMatricula}
                          onChange={(e) => {
                            this.setState({
                              idMatricula: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Mensagem de Aviso</label>
                        <input
                          className="form-control"
                          value={this.state.mensagemBloqueio}
                          onChange={(e) => {
                            this.setState({
                              mensagemBloqueio: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>

                    {(!("id" in selectedRow))&& (
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Tipo de recurso</label>
                          <Nav className='me-auto'>
                            <NavDropdown
                              className="ddlFundoBrancoSemBorda form-control"
                              value={selectedRow.action || BlockedResourcesTypes[0].action}
                              title={selectedRow.action || BlockedResourcesTypes[0].action}
                            >
                              {BlockedResourcesTypes.map((resource, idx) => (
                                <NavDropdown.Item
                                  key={idx}
                                  onClick={() => {
                                    this.setState({
                                      selectedRow: {
                                        ...selectedRow,
                                        action: resource.action
                                      }
                                    })
                                  }}
                                  className="cssScroll dropDownItemSemBorda"
                                >
                                  {resource.action} - {resource.description}
                                </NavDropdown.Item>
                              ))}
                            </NavDropdown>
                          </Nav>
                        </div>
                      </div>
                    )}

                    <div className="col-md-1 mb-3">
                      <Button
                        type="button"
                        variant="primary"
                        disabled={this.state.isLoading || !this.state.idMatricula}
                        style={{marginTop: 15}}
                        onClick={this.handleSaveBlockedResource}
                      >
                        {this.state.isLoading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                          >
                            <span className="visually-hidden">Carregando...</span>
                          </Spinner>
                        ) : "Salvar"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBase>
          </div>
        </div>
      </div>
    );
  }
}

function Wrapper(props) {
  const unidades = useRecoilValue(unidadesState);
  const matriculas = useRecoilValue(matriculasState);
  return (
    <BlockedResources
      unidades={unidades}
      matriculas={matriculas}
      {...props}
    />
  )
}

export default withAppContext(withUnidadeMatricula(Wrapper, "Bloqueio de Recursos"));