import {useEffect, useState} from "react";
import {GetFicha, GetOV, GetTransacoes} from "./service/manutencao-financeira-service";
import objectPropFirstLetterToUpper from "./utils/objectPropFirstLetterToUpper";
import {v4 as uuidv4} from "uuid";

/**
 * @param {'ov'|'ficha'|'transacao'} modelName
 * @param matricula
 * @returns {[*[],((value: (((prevState: *[]) => *[]) | *[])) => void),boolean]}
 */
export default function useManutencaoFinanceiraFrankFetch(modelName = "ov", matricula) {
  const [isLoadingFetchData, setIsLoadingFetchData] = useState(false);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState(null);

  useEffect(() => {
    async function loadTableData() {
      if (modelName) {
        setIsLoadingFetchData(true);
        setRows([]);
        switch (modelName) {
          case "ficha":
          {
            const resp = await GetFicha(matricula.idMatricula);
            const ficha = {
              ...objectPropFirstLetterToUpper(resp),
            }

            const newRows = (ficha?.FinancialStatementList || []).map(r => {
              r.id = uuidv4();
              delete r.__typename;
              delete r.Id;
              return r;
            });

            setData(ficha);
            setRows(newRows);
          }
            break;
          case "ov":
          {
            const resp = await GetOV(matricula.idMatricula);

            const newRows = resp.map(r => {
              r = objectPropFirstLetterToUpper(r)
              r.id = uuidv4();
              delete r.__typename;
              delete r.Id;
              return r;
            });

            setRows(newRows);
          }
            break;
          case "transacao":
          {
            const resp = await GetTransacoes(matricula.idMatricula);
            const newRows = [];
            const transactionsKeys = ["firstInstallment", "monthlyPayment"];

            resp.forEach(r => {
              if (r?.financialTransDetail?.transactions) {
                const transactions = r.financialTransDetail.transactions;
                delete transactions.__typename;

                Object.keys(transactions).forEach(serviceTypeName => {
                  const service = transactions[serviceTypeName];

                  transactionsKeys.forEach(transactionKey => {
                    if (service && transactionKey in service && service[transactionKey].length) {
                      service[transactionKey].forEach(installment => {
                        const camelCased = objectPropFirstLetterToUpper(installment);

                        delete camelCased.__typename;
                        delete camelCased?.CustCard?.__typename;
                        delete camelCased?.CustCard?.Recurrent?.__typename;
                        delete camelCased?.BankAccount?.__typename;
                        delete camelCased?.BankingBillet?.__typename;

                        const newTransaction = camelCased;
                        newTransaction.dataAreaId = sessionStorage.getItem("@SECRETARIA/dataAreaId");
                        newTransaction.fiscalEstablishmentId = sessionStorage.getItem("@SECRETARIA/entidadeLegal");
                        newTransaction.matriculaId = matricula.codigoMatriculaErp;
                        newTransaction.financialMovType = r?.financialTransDetail?.financialMovType;
                        newTransaction.custAccount = r?.financialTransDetail?.custAccount;
                        newTransaction.transStatus = r?.financialTransDetail?.transStatus;
                        newTransaction.tipoDeTransacao = serviceTypeName;
                        newTransaction.tipoDeParcela = transactionKey;
                        newTransaction.id = uuidv4();
                        delete newTransaction.__typename;
                        delete newTransaction.Id;
                        newRows.push(newTransaction);
                      });
                    }
                  })
                })
              }
            });

            setRows(newRows);
          }
            break;
        }
        setIsLoadingFetchData(false);
      }
    }

    loadTableData();
  }, [modelName]);


  return [rows, setRows, isLoadingFetchData, data]
}
