import {useCallback} from "react";
import {useRecoilValue} from "recoil";
import {permissionsState} from "../recoil/atoms/permissionsState";


interface UseCan {
  userCan: (permission: string) => boolean
  somePermission: (...permissions: string[]) => boolean
}

export const useCan = (): UseCan => {
  const userPermissions = useRecoilValue(permissionsState);

  const userCan = useCallback((permission: string) => {
    return userPermissions?.map(p => p?.permissionName).includes(permission)
  }, [userPermissions])

  const somePermission = useCallback((...permissions: string[]) => {
    return permissions.some(userCan)
  }, [userCan])

  return {
    userCan,
    somePermission
  }
}
