import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import {Unidade} from "../../components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";

export const selectedAnoLetivoState = atom<string>({
  key: 'selectedAnoLetivo',
  default: new Date().getFullYear().toString(),
  effects: [
    persistAtomEffect
  ]
});
