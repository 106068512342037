import React, {useState} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import "./styles.css";
import ReactTooltip from "react-tooltip";
import {AuthContext} from "../../context/AuthContext";
import SalesOrder from "../../models/SalesOrder";
import FinancialStatement from "../../models/FinancialStatement";
import Transaction from "../../models/Transaction";
import useManutencaoFinanceiraFrankFetch from "../../useManutencaoFinanceiraFrankFetch";
import contextKeys from "../../utils/contextKeys";
import manutencaoFinanceiraModelKeynames from "../../utils/manutencaoFinanceiraModelKeynames";
import Content from "./Content";
import LoadingAnimation from "../LoadingAnimation/LoadigAnimation";

function ModalFinanceiroFrank(
  {
    matricula,
  }
) {
  const [activeTab, setActiveTab] = useState(manutencaoFinanceiraModelKeynames.OV);
  const [rows, setRows, isLoadingFetchData, data] = useManutencaoFinanceiraFrankFetch(activeTab, matricula);
  const [deletingRows, setDeletingRows] = useState([]);
  const [editingRows, setEditingRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const renderTab = (tableColumns, rows) => {
    return (
      <Content
        matricula={matricula}
        tableColumns={tableColumns}
        rows={rows}
        data={data}
        setRows={setRows}
        activeTab={activeTab}
        isLoadingFetchData={isLoadingFetchData}
        setActiveTab={setActiveTab}
        resetTableState={resetTableState}
        deletingRows={deletingRows}
        setDeletingRows={setDeletingRows}
        editingRows={editingRows}
        setEditingRows={setEditingRows}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    )
  }

  const resetTableState = (eventKey) => {
    setActiveTab(eventKey);
  }

  if (isLoadingFetchData) {
    return (
      <LoadingAnimation/>
    )
  }

  return (
    <AuthContext.Consumer>
      {({ appContext }) => {
        const { tables } = appContext || {};
        const ovsTableColumns = tables[contextKeys.tables.FRANK_OV_SALE_ORDER]?.columns || [];
        const fichaTableColumns = tables[contextKeys.tables.FRANK_FICHA_FINANCIAL_STATEMENT]?.columns || [];
        const transacaoTableColumns = tables[contextKeys.tables.FRANK_TRANSACAO_TRANSACTION]?.columns || [];

        return (
          <div className="seb-secretaria-component-modal-financeiro">
            <ReactTooltip
              place="top"
              type="dark"
              effect="float"
              className="tooltip"
            />

            <Tabs
              defaultActiveKey={activeTab}
              className="mb-3"
              onSelect={resetTableState}
            >
              <Tab eventKey={manutencaoFinanceiraModelKeynames.OV} title="Ordens de Venda">
                {renderTab(ovsTableColumns, rows)}
              </Tab>
              <Tab eventKey={manutencaoFinanceiraModelKeynames.FICHA} title="Fichas">
                {renderTab(fichaTableColumns, rows)}
              </Tab>
              <Tab eventKey={manutencaoFinanceiraModelKeynames.TRANSACAO} title="Transações">
                {renderTab(transacaoTableColumns, rows)}
              </Tab>
            </Tabs>
          </div>
        )
      }}
    </AuthContext.Consumer>
  );
}

export default ModalFinanceiroFrank;
