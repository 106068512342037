
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import SvgIcon from '@mui/material/SvgIcon';

import Users03 from "../../untitled-ui/duocolor/users-03";
import {usePopover} from "../../hooks/use-popover";
import {AccountPopover} from "./account-popover";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../context/AuthContext";

export const AccountButton = () => {
  const popover = usePopover();
  const { userImage } = useContext(AuthContext);
  const [ nomeUsuarioLogado, setNomeUsuarioLogado ] = useState('');
  useEffect(() => {
    setNomeUsuarioLogado(sessionStorage.getItem('@SECRETARIA/nomeUsuarioLogado').split(' ')[0]);
  }, []);

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 1,
          p: 2
        }}
      >
        Olá, {nomeUsuarioLogado}
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'divider',
            borderRadius: '50%'
          }}
        >
          <Avatar
            sx={{
              height: 32,
              width: 32
            }}
            src={userImage}
          >
            <SvgIcon>
              <Users03/>
            </SvgIcon>
          </Avatar>
        </Box>
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
