import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function signIn(authToken) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      signIn
    },
    error,
    errors
  } = await client.query({
    query: gql`
        mutation SignIn($signInData: AuthMutation) {
            signIn(data: $signInData) {
                permissions {
                    permissionName
                    systemId
                }
            }
        }
    `,
    variables: {
      signInData: {
        type: "MSAL",
        systemId: process.env.REACT_APP_CLIENT_ID,
        msal: {
          authToken
        }
      }
    }
  })

  if (error) {
    alert("Erro ao listar matriculas.");
  }

  dispatchGraphError(errors);

  return signIn
}
