import {blue, green, indigo, purple, localhost, homolog, production, development} from './colors';

export const getPrimary = (preset) => {
  switch (preset) {
    case 'blue':
      return blue;
    case 'green':
      return green;
    case 'indigo':
      return indigo;
    case 'purple':
      return purple;
    case 'localhost':
      return localhost;
    case 'development':
      return development;
    case 'homolog':
      return homolog;
    case 'production':
      return production;
    default:
      console.error('Invalid color preset, accepted values: "blue", "green", "indigo" or "purple"".');
      return blue;
  }
};
