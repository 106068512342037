function siteTheme() {
    const element = document.querySelector('body');
    if (window.location.hostname === 'localhost' || window.location.hostname === 'hmg.secretariadigital.seb.com.br') {
        element.style.setProperty('--primary-color', '#00d427');
        element.style.setProperty('--primary-color-drop', '#00bb22');
        element.style.setProperty('--primary-color-onBack', '#00ee2c');
        element.style.setProperty('--primary-color-disable', '#00a11e');
        element.style.setProperty('--primary-color-background', '#008819'); 
        element.style.setProperty('--primary-color-selecionado', '#08ff35');
    } else if (window.location.hostname === 'dev.secretariav2.seb.com.br') {
        element.style.setProperty('--primary-color', '#fcc11c');
        element.style.setProperty('--primary-color-drop', '#b08202');
        element.style.setProperty('--primary-color-onBack', '#e3a703');
        element.style.setProperty('--primary-color-disable', '#ca9502');
        element.style.setProperty('--primary-color-background', '#b08202');
        element.style.setProperty('--primary-color-selecionado', '#fdc835');
    }
}

export default siteTheme;