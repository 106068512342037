import FrankJsonDownload from "./FrankJsonDownload";

export default class OV extends FrankJsonDownload {
  DataAreaId = "HEB";
  MatriculaId = "SEB-0468503";
  /** @type {SalesOrder[]} */
  SalesOrderList = [];
  
  /** @param {SalesOrder} salesOrder */
  addSalesOrder(salesOrder) {
    this.SalesOrderList.push(salesOrder)
  }
}