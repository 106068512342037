import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import {Matricula} from "../../components/MatriculaAutocompleteSelect/MatriculaAutocompleteSelect";

export const selectedMatriculaState = atom<Matricula|null>({
  key: 'selectedMatriculaState',
  default: null,
  effects: [
  ]
});
