import React, {FC, useMemo} from 'react';
import {createComponent} from '@lit/react';
import {SebCsat as CsatImport} from 'seb-interactive-islands';
import {useLocation} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {ApolloClient, DocumentNode, gql, InMemoryCache, NormalizedCacheObject} from "@apollo/client";
import "seb-interactive-islands/seb-csat.js";
import {selectedUnidadeState} from "../../recoil/atoms/selectedUnidadeState";
import {selectedMatriculaFullState} from "../../recoil/atoms/selectedMatriculaFullState";
const {SebCsat,  createMutation, typeDefs, updateMutation} = CsatImport;

interface CsatProps extends HTMLElement {
  client: ApolloClient<NormalizedCacheObject>
  createMutation: DocumentNode
  updateMutation: DocumentNode
  type: string
  position: string
  scoreTitle: string
  canClose: string
  show: boolean
  context: string
}

interface CsatEvents {
  onScoreClicked: string
  onClosed: string
  onSubmit: string
}

const LitComponent = createComponent<CsatProps>({
  tagName: 'seb-csat',
  //@ts-ignore
  elementClass: SebCsat,
  react: React,
  events: {
    onScoreClicked: 'score-clicked',
    onClosed: 'closed',
    onSubmit: 'submit'
  },
});

declare global {
  interface Window {
    appVersion: string
    DD_RUM: {
      getInternalContext: () => string
      getSessionReplayLink: () => string
    }
  }
}

interface Props {
  show: boolean
  scoreTitle: string
  children?: any
  context?: any
}

const Csat: FC<Props> = (props) => {
  const {
    show,
    scoreTitle = "Como você avalia o atendimento?",
    children,
    context = {},
    ...rest
  } = props;
  const dd = window?.DD_RUM || {} as {
    getInternalContext: () => string
    getSessionReplayLink: () => string
  };
  const {
    //@ts-ignore
    getInternalContext = () => '',
    //@ts-ignore
    getSessionReplayLink = () => '',
  } = dd || {};
  const ddContext = getInternalContext();
  const location = useLocation();
  const selectedMatricula = useRecoilValue(selectedMatriculaFullState);
  const selectedUnidade = useRecoilValue(selectedUnidadeState);
  const defaultContext = useMemo(() => {
    return JSON.stringify({
      version: window.appVersion,
      dd: ddContext,
      dd_replay: getSessionReplayLink(),
      question: scoreTitle,
      path: location.pathname,
      unidadeId: selectedUnidade ? selectedUnidade.id : {},
      unidade: selectedUnidade,
      emailUsuarioLogado: sessionStorage.getItem('@SECRETARIA/emailUsuarioLogado'),
      matricula: selectedMatricula || {},
      ...context,
    })
  }, [scoreTitle, location.pathname, context]);

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: process.env.REACT_APP_API_URL || "",
    typeDefs: gql(typeDefs),
  })

  return (
    <LitComponent
      client={client}
      createMutation={gql(createMutation)}
      updateMutation={gql(updateMutation)}
      type="popup"
      position="bottom"
      scoreTitle={scoreTitle}
      canClose="true"
      show={show}
      context={defaultContext}
      {...rest}
    >
      {children}
    </LitComponent>
  );
}

export default Csat;
