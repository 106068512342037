import React, {useEffect, useState} from "react";
import includesSearch from "../../utils/includesSearch";
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import CustomTable from "../../components/CustomTable";
import contextKeys from "../../utils/contextKeys";
import {AuthContext} from "../../context/AuthContext";
import SearchInput from "../../components/SearchInput/SearchInput";
import "./styles.css";
import PanelButton from "../../components/PanelButton/PanelButton";
import {SaveTwoTone} from "@mui/icons-material";
import Form from 'react-bootstrap/Form';
import {Alert} from "react-bootstrap";
import * as _matriculaService from "../../service/matricula-service";
import {useRecoilValue} from "recoil";
import {selectedUnidadeState} from "../../recoil/atoms/selectedUnidadeState";
import {selectedAnoLetivoState} from "../../recoil/atoms/selectedAnoLetivoState";
import * as _integracaoMatriculaService from '../../service/integracaoMatricula-service';
import * as Message from "../../components/shared/Message";
import formatDateToInput from "../../utils/formatDateToInput";

function ReplayParcialMaterial() {
  const selectedUnidade = useRecoilValue(selectedUnidadeState);
  const year = useRecoilValue(selectedAnoLetivoState)
  const [search, setSearch] = useState("");
  const [matriculas, setMatriculas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loadingMatriculas, setLoadingMatriculas] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [requestData, setRequestData] = useState({
    qntParcelaMaterial: 0,
    dataReferencia: formatDateToInput(new Date()),
    envioItems: {
      enviarFichaFinanceira: false,
      enviarTransacaoFinanceira: false,
      enviarServicoMatricula: false,
      enviarOrdemVenda: false,
      enviarServicoMaterial: false,
    }
  })

  useEffect(() => {
    if (selectedUnidade && selectedUnidade.id && loadData) {
      setLoadingMatriculas(true);
      setMatriculas([]);
      const request = {
        CodigoMatricula: "",
        CodigoMatriculaErp: "",
        AnoLetivo: year,
        Estudante_Name: "",
        CodigoCliente: "",
        IdUnidade: selectedUnidade.id,
        PossuiMaterial: false,
        TipoFiltro: "matriculas"
      };

      _matriculaService.ListMatriculas(request)
        .then(matriculas => {
          setMatriculas((matriculas || []).filter(m => String(m.status).toLowerCase() !== "cancelada"));
          setLoadingMatriculas(false);
        }).finally(() => {
          setLoadData(false);
        })
    }
  }, [selectedUnidade, year, loadData]);

  const handleSelection = (selection = []) => {
    setSelectedRows(selection);
  }

  const handleSearch = (event) => {
    setSearch(event.target.value);
  }

  const filterBySearch = (row) => {
    if (!search) return true;

    const rowValues = Object.values(row);
    const searchValues = rowValues.filter(value => includesSearch(value, search));

    return searchValues.length > 0;
  }

  const handleSend = async () => {
    if (isSending) return;

    try {
      setIsSending(true);

      const request = (selectedRows || []).map(r => {
        const matricula = matriculas.find(m => m.codigoMatricula === r);
        return {
          ...requestData,
          codigoMatricula: matricula.codigoMatricula,
        }
      })

      const result = await _integracaoMatriculaService.ReplayParcialMaterial(request)

      if (result != null) {
        Message.ExibeListMensagem(result.messages);
      } else {
        Message.ExibeMensagem("Não foi possível obter retorno da rotina, verifique se código ERP foi criado na matrícula", "2");
      }
    } catch (e) {
      Message.ExibeMensagem("Não foi possível obter retorno da rotina, verifique se código ERP foi criado na matrícula", "2");
    } finally {
      setIsSending(false);
      setLoadData(true);
    }
  }

  return (
    <AuthContext.Consumer>
      {({ appContext }) => {
        const {tables} = appContext || {};
        const tableColumns = tables[contextKeys.tables.REPLAY_PARCIAL_REGULAR]?.columns || [];

        return (
          <div className="seb-secretaria-component-replay-parcial-regular panel">
            <div className="filtros">
              <div className="row mb-3">
                <div className="col-md-3">
                  <SearchInput
                    value={search}
                    onChange={handleSearch}
                  />
                </div>

                <div className="col-md-9">
                  <div className="row justify-content-end m-r-1">
                    <PanelButton
                      isLoading={isSending}
                      loadingTitle="Enviando..."
                      disabled={!selectedRows.length}
                      onClick={handleSend}
                      icon={<SaveTwoTone/>}
                      tip="Enviar material da(s) matrícula(s) selecionada(s)"
                      showBadge={!!selectedRows.length}
                      badgeValue={selectedRows.length}
                    />
                  </div>
                </div>
              </div>

              <hr/>

              <div className="row mb-3">
                <div className="row mb-3">
                  <div className="col-md-3">
                    <Form.Group controlId="dataReferencia">
                      <Form.Label>Data de Referência</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Data de Referência"
                        value={requestData?.dataReferencia}
                        onChange={e => setRequestData({...requestData, dataReferencia: e.target.value})}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-3">
                    <Form.Group controlId="parcelasMaterial">
                      <Form.Label>Quantidade de parcelas do material</Form.Label>
                      <Form.Select
                        aria-label="Quantidade de parcelas do material"
                        value={requestData?.qntParcelaMaterial}
                        onChange={e => setRequestData({...requestData, qntParcelaMaterial: parseInt(e.target.value)})}
                      >
                        <option>Parcelamento material</option>
                        <option value="0">à vista</option>
                        {[...Array(12).keys()].map(r => (
                          <option value={r+1}>{r+1}X</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="enviarFichaFinanceira">
                      <Form.Check
                        type="checkbox"
                        label="Enviar Ficha"
                        checked={requestData?.enviarFichaFinanceira}
                        onChange={e => setRequestData({...requestData, envioItems: {...requestData.envioItems, enviarFichaFinanceira: Boolean(e.target.value)}})}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="enviarTransacaoFinanceira">
                      <Form.Check
                        type="checkbox"
                        label="Enviar Transações"
                        checked={requestData?.enviarTransacaoFinanceira}
                        onChange={e => setRequestData({...requestData, envioItems: {...requestData.envioItems, enviarTransacaoFinanceira: Boolean(e.target.value)}})}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="enviarOrdemVenda">
                      <Form.Check
                        type="checkbox"
                        label="Enviar Ordem de Venda"
                        checked={requestData?.enviarOrdemVenda}
                        onChange={e => setRequestData({...requestData, envioItems: {...requestData.envioItems, enviarOrdemVenda: Boolean(e.target.value)}})}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="enviarServicoMaterial">
                      <Form.Check
                        type="checkbox"
                        label="Enviar Serviço Material"
                        checked={requestData?.enviarServicoMaterial}
                        onChange={e => setRequestData({...requestData, envioItems: {...requestData.envioItems, enviarServicoMaterial: Boolean(e.target.value)}})}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-md-11">
                  <Alert className="text-center" variant="warning">
                    Caso um dos itens já existir no ERP ser atentar a quantidade de parcelas para encaminhar os demais itens
                  </Alert>
                </div>
              </div>
            </div>

            <CustomTable
              columns={tableColumns}
              data={(matriculas || []).filter(filterBySearch).map(r => ({...r, id: r.codigoMatricula}))}
              configs={{
                selection: true,
              }}
              selected={selectedRows}
              onSelectionChange={handleSelection}
              isLoading={loadingMatriculas}
            />
          </div>
        )
      }}
    </AuthContext.Consumer>
  );
}

export default withUnidadeMatricula(
  ReplayParcialMaterial,
  'Replay Parcial Material',
  undefined,
  {
    needMat: false
  }
);
