import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const selectedMatriculaFullState = atom<null>({
  key: 'selectedMatriculaFullState',
  default: null,
  effects: [
    persistAtomEffect
  ]
});
