import React from 'react';
import './styles.css';
import classNames from "../../utils/classNames";
import ShortCuts from "../ShortCuts/ShortCuts";

interface KeyProps {
  bindKey?: string;
  children: React.ReactNode;
  color?: 'black' | 'white';
  onMatch?: VoidFunction;
  onPress?: (bindKey: string) => void;
  onRelease?: (bindKey: string) => void;
  activeDelay?: number;
}

function Key(props: KeyProps) {
  const {
    color = 'white',
    bindKey,
    onMatch,
    onPress,
    onRelease,
    activeDelay = 0,
  } = props;
  const [active, setActive] = React.useState(false);

  const handleActive = () => {
    if (activeDelay) {
      setTimeout(() => setActive(true), activeDelay);
    } else {
      setActive(true);
    }
  }

  return (
    <span
      className={classNames(
      "seb-key-container",
        color === 'black' ? "seb-key-container-black" : undefined,
        color === 'white' ? "seb-key-container-white" : undefined,
        active ? "seb-key-container-active" : undefined
      )}
    >
      {bindKey && (
        <ShortCuts
          commands={[
            {
              handler: e => e.key === bindKey,
              onPress: () => {
                handleActive();
                if (onPress) onPress(bindKey);
              },
              onRelease: () => {
                setActive(false);
                if (onRelease) onRelease(bindKey);
              },
              onMatch,
              ignore: false
            },
          ]}
        />
      )}
      {props.children}
    </span>
  );
}

export default Key;