import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { InserirResponsavel } from "./InserirResponsavel";
import * as _responsavelService from '../../service/responsavel-service';
import * as Message from '../../components/shared/Message';

export class Responsavel_rateio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Matricula: this.props.Matricula,
            Acao: "",
            Responsaveis: [],
            Loading: false,
            jaExisteRateio: false
        }
    }

    async componentDidMount() {
        this.setState({ Acao: "loading" });
        await this.ListaResponsaveis();
        this.setState({ Acao: "" });
    }
    async ListaResponsaveis() {
        var result = await _responsavelService.ListResponsaveisMatriculaByCodigoErp(this.state.Matricula.idMatricula);

        if (result != null && result != undefined && result.length > 0) {

            result.map((responsavel) => {
                if (parseFloat(responsavel.rateio) > 0 && parseFloat(responsavel.rateio) < 100.0) {
                    this.setState({ jaExisteRateio: true });
                }
            })

            if (this.state.jaExisteRateio) {
                Message.ExibeMensagem("Rateio já aplicado para está matrícula", "1", true);
            } else {
                this.setState({ Responsaveis: result });
            }
        }
    }
    Acao(acao) {
        this.setState({ Acao: acao });
    }
    NovoResponsavel() {
        var responsaveis = this.state.Responsaveis;
        if (responsaveis.length == 2) {
            Message.ExibeMensagem("Responsáveis ja inseridos para o rateio", "2", true);
            return false;
        }
        this.Acao("novo-responsavel")
    }
    OnChange(id, valor) {
        const {
            Responsaveis = []
        } = this.state;
        const responsavel = Responsaveis.find(responsavel => responsavel.id === id);
        const index = Responsaveis.indexOf(responsavel);
        responsavel.rateio = String(valor);

        Responsaveis[index] = responsavel;

        this.setState({ Responsaveis });
    }
    Validacao() {
        var responsaveis = this.state.Responsaveis;
        var rateio = 0;
        responsaveis.map((responsavel) => {
            rateio += parseFloat(responsavel.rateio);
        })
        if (rateio != 100) {
            Message.ExibeMensagem("Rateio total precisa ser de 100%", "2", true);
            return false;
        }
        if (responsaveis.length != 2) {
            Message.ExibeMensagem("Necessario 2 responsáveis para o rateio", "2", true);
            return false;
        }

        return true;
    }
    async OnSubmit() {
        if (this.Validacao()) {
            this.setState({ Loading: true });
            var result = await _responsavelService.FinalizarRateio(this.state.Matricula.idMatricula, this.state.Responsaveis);
            if (result != undefined && result != null && result.statusCode != undefined && result.statusCode == 200 && result.value == true) {
                Message.ExibeMensagem("Atualizado com sucesso!!", '0', true);
                this.Acao("rateio-sucesso");
            } else {
                if (result.value != undefined) {
                    Message.ExibeMensagem(result.value, "2", true);
                } else if (result.title != undefined) {
                    Message.ExibeMensagem(result.title, "2", true);
                } else {
                    Message.ExibeMensagem("Erro ao criar responsavel!", "2", true);
                }

            }
            this.setState({ Loading: false });
        }
    }
    render() {
        const { Acao, Matricula, Responsaveis, Loading, jaExisteRateio } = this.state;
        switch (Acao) {
            case "loading":
                return (<div className="form-group d-flex justify-content-center"><img width="50" height="50" src="img/loading.gif" /></div>);
                break;
            case "novo-responsavel":
                return <InserirResponsavel Matricula={ Matricula } />;
                break;
            case "rateio-sucesso":
                return (
                    <div >
                        <div className=" form-group d-flex justify-content-center">
                            <h3 style={{ color: '#0068b2' }}> <strong>RATEIO DA MATRÍCULA {this.props.Matricula?.codigoMatricula} REALIZADO COM SUCESSO! </strong>
                            </h3>
                        </div>
                    </div>
                );
                break;
            default:
                return (
                    <>
                        
                        <div className="row">
                            <div className="col-md-2">
                                {jaExisteRateio == false &&
                                    <button className="btn btn-primary" onClick={() => this.NovoResponsavel()} >Inserir Novo</button>
                                }
                            </div>
                        </div>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Codigo Crm</th>
                                    <th>Codigo Erp</th>
                                    <th>Cpf</th>
                                    <th>Rateio(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Responsaveis != undefined && Responsaveis.length > 0 && (
                                        Responsaveis.map((responsavel, index) => {
                                            return <tr key={index}>
                                                        <td>{responsavel.nome}</td>
                                                        <td>{responsavel.codigoCliente}</td>
                                                        <td>{responsavel.codigoErp}</td>
                                                <td>{responsavel.cpf}</td>
                                                <td><input type="number" name="rateio" value={responsavel.rateio} onChange={(v) => this.OnChange(responsavel.id, v.target.value)} /></td>
                                                    </tr>
                                            }
                                        )
                                    )
                                }
                               
                            </tbody>

                        </Table>
                        <div className="row">
                            <div className="col-md-2">
                                {jaExisteRateio == false &&
                                    <button disabled={Loading ? "disabled" : ""}
                                        className="btn btn-primary"
                                        onClick={() => this.OnSubmit()} >{Loading ? "Salvando" : "Salvar"}
                                        {Loading ? <img width="25" height="25" src="img/loading.gif" /> : ''}
                                    </button>
                                }
                            </div>
                        </div>
                        
                    </>
                );
        }
    }
}