import React from 'react';
import {Badge} from "react-bootstrap";

function EditableBadge() {
  return (
    <Badge
      className="badge-editable"
      pill
      bg="warning"
      text="dark"
      data-tip="Duplo clique na célula para editar"
    >
      editável
    </Badge>
  );
}

export default EditableBadge;