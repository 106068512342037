import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function ValoresReparcelamentoServicos(idMatricula, request) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      valoresReparcelamentoServicos
    },
    errors
  } = await client.mutate({
    mutation: gql`
    mutation ValoresReparcelamentoServicos($filter: ValoresReparcelamentoServicosInput) {
      valoresReparcelamentoServicos(filter: $filter) {
        statusCode
        messages {
          text
          typeMessage
        }
        value {
          matriculaId
          obrigatorios {
            servicoId
            nome
            parcelamentoAnterior
            parcelamentoNovo
            valorContratoAnterior
            valorContratoNovo
            detalhes {
              numParcela
              valorNovo
              valorAnterior
            }
          }
          opcionais {
            servicoId
            nome
            parcelamentoAnterior
            parcelamentoNovo
            valorContratoAnterior
            valorContratoNovo
            detalhes {
              numParcela
              valorNovo
              valorAnterior
            }
          }
          servicoPrincipal {
            servicoId
            nome
            parcelamentoAnterior
            parcelamentoNovo
            valorContratoAnterior
            valorContratoNovo
            detalhes {
              numParcela
              valorNovo
              valorAnterior
            }
          }
          servicoPrincipalEntrada {
            servicoId
            nome
            parcelamentoAnterior
            parcelamentoNovo
            valorContratoAnterior
            valorContratoNovo
            detalhes {
              numParcela
              valorAnterior
              valorNovo
            }
          }
        }
      }
    }
    `,
    variables: {
      filter: {
        tipoMovimentacao: 284360004,
        ...request
      }
    }
  })

  dispatchGraphError(errors);

  return valoresReparcelamentoServicos;
}
export async function ChangeCalculatorValues(idMatricula, request){
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      alteraValoresReparcelamentoServicos
    },
    errors
  } = await client.mutate({
    mutation: gql`
    mutation AlteraValoresReparcelamentoServicos($filter: ValoresReparcelamentoServicosInput) {
      alteraValoresReparcelamentoServicos(filter: $filter) {
        value
        messages {
          text
          typeMessage
        }
        statusCode
      }
    }
    `,
    variables: {
      filter: request
    }
  })

  dispatchGraphError(errors);

  return alteraValoresReparcelamentoServicos;
}