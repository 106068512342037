import "./styles.css";
import formatDateToInput from "../../utils/formatDateToInput";
import formatDateToDisplay from "../../utils/formatDateToDisplay";

function EditableTd(
  {
    editable = false,
    value,
    onChange,
    handleOnDoubleClick,
    onKeyPress,
    type = "text",
    step,
    min,
    max,
    options = [],
  }
) {
  const handleOnChange = event => {
    let value = String(event.target.value).trim();
    switch (type) {
      case "number":
        value = Number(value);
        break;
    }
    if (onChange) {
      onChange(value);
    }
  }
  
  
  const handleOnKeyPress = event => {
    if (onKeyPress) {
      onKeyPress(event);
    }
  }
  
  const renderEditable = () => {
    switch (type) {
      case "select":
        return (
          <select
            onDoubleClick={handleOnDoubleClick}
            className="seb-secretaria-component-editable-td-input"
            value={value}
            onChange={handleOnChange}
            onKeyUp={handleOnKeyPress}
          >
            {
              options.map((option) => (
                <option 
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))
            }
          </select>
        )
      case "textarea":
        return (
          <textarea
            onDoubleClick={handleOnDoubleClick}
            className="seb-secretaria-component-editable-td-input"
            value={value}
            onChange={handleOnChange}
            rows={5}
          />
        )
      case "date":
        let date = value?.length ? value : "1900-01-01";
        date = formatDateToInput(date);
        return (
          <input
            onDoubleClick={handleOnDoubleClick}
            className="seb-secretaria-component-editable-td-input"
            value={date}
            onChange={event => {
              handleOnChange({
                ...event,
                target: {
                  ...event.target,
                  value: event.target.value.split("-").reverse().join("/"),
                }
              });
            }}
            type={type}
            height={100}
            step={step}
            min={min}
            max={max}
          />
        )
      default:
        return (
          <input
            onDoubleClick={handleOnDoubleClick}
            className="seb-secretaria-component-editable-td-input"
            value={value}
            onChange={handleOnChange}
            type={type}
            height={100}
            step={step}
            min={min}
            max={max}
          />
        )
      break;
    }
  }
  const renderDisplay = () => {
    switch (type) {
      case "date":
        return formatDateToDisplay(value);
      default:
        return value;
    }
  }
  
  return (
    <td 
      className={[
        "seb-secretaria-component-editable-td",
        editable ? "editable" : "",
      ].join(" ")}
      onDoubleClick={handleOnDoubleClick}
    >
      {editable ? renderEditable() : renderDisplay()}
    </td>
  );
}

export default EditableTd;