import FrankJsonDownload from "./FrankJsonDownload";

export default class Ficha extends FrankJsonDownload {
  DataAreaId = "MBB";
  FiscalEstablishmentId = "4502";
  MatriculaId = "SEB-0468503";
  /** @type {FinancialStatement[]} */
  FinancialStatementList = [];

  /** @param {FinancialStatement} financialStatement */
  addFinancialStatement(financialStatement) {
    this.FinancialStatementList.push(financialStatement)
  }
}