import React, {Component} from 'react';
import * as _matriculaService from "../../service/matricula-service.js";
import * as _movimentacoesService from '../../service/movimentacoes-service.js';
import * as _turmaService from "../../service/turma-service.js";
import * as formValidacao from "../../Helpers/form-validacao.js";
import * as Message from "../../components/shared/Message";
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import LoadingAnimation from "../../components/LoadingAnimation/LoadigAnimation";
import csatList from "../../utils/csatList";
import {FormControl, MenuItem, Select} from "@mui/material";

class TrocaDeTurma extends Component {
  static displayName = TrocaDeTurma.name;

  constructor(props) {
    super(props);
    this.state = this.getState()
  }

  getState() {
    return {
      isLoading: false,
      isLoadingBtn: false,
      errors: {},
      Matricula: this.props.Matricula,
      DadosMatricula: {},
      Turmas: [],
      DadosNovaTurma: {
        novaTurma: "",
        periodo: this.props?.Matricula?.periodo
      },
      nomeTurma: "",
      permiteMovimentarAvista: true
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.Matricula !== this.props.Matricula) {
      this.setState(this.getState(), () => {
        this.getData();
      })
    }
  }

  async componentDidMount() {
    this.getData();
  }

  getData = async () => {
    this.setState({isLoading: true});
    let result = await _matriculaService.GetMatricula(this.state.Matricula.idMatricula);
    let dadosMatricula = {};
    let turmas = [];
    if (result != null && result != undefined) {
      dadosMatricula = result;
      let resultTurmas = await _turmaService.TurmasServicoUnidade(dadosMatricula.servico_Id, {
        filterStatus: "ATIVO",
        comVagaDisponivel: true
      });
      if (resultTurmas != null && resultTurmas != undefined) {
        turmas = resultTurmas;
      }
    }
    let idUnidade = this.props.Unidade.id;
    let permiteMovimentarAvista = await _movimentacoesService.PermiteMovimentarAvista(this.state.Matricula.idMatricula, idUnidade, 284360003, 0);

    turmas = (turmas || []).filter(t => t.id !== dadosMatricula?.turma_Id)

    this.setState({
      isLoading: false,
      DadosMatricula: dadosMatricula,
      Turmas: turmas,
      permiteMovimentarAvista: permiteMovimentarAvista.value
    });
  }

  FormValidation() {
    let errors = {};
    let formIsValid = true;
    let inputs = this.state.DadosNovaTurma;

    if (!formValidacao.isValidComboBox(inputs.novaTurma)) {
      errors["novaTurma"] = "Campo obrigatório.";
      formIsValid = false;
    } else {
      errors["novaTurma"] = "";
      formIsValid = true;
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  OnChange(event) {
    var campos = this.state.DadosNovaTurma;
    campos[event.target.name] = event.target.value;
    this.setState({DadosNovaTurma: campos});
  }

  async Salvar(event) {
    event.preventDefault();
    try {
      if (!this.FormValidation()) {
        return false;
      }
      this.setState({isLoadingBtn: true});
      var result = await _turmaService.AtualizarTurma(this.state.Matricula.idMatricula, this.state.DadosNovaTurma.novaTurma);
      if (result != null && result != undefined && result.id != undefined && result.id != null) {
        var dadosMatricula = {...this.state.DadosMatricula};
        var dadosNovaTurma = {...this.state.DadosNovaTurma};
        var turma = this.state.Turmas.find(x => x.id == this.state.DadosNovaTurma.novaTurma);
        dadosMatricula.turma_Name = turma.nome;
        dadosMatricula.turma_Id = turma.id;
        dadosNovaTurma.periodo = turma.periodo;
        Message.ExibeMensagem("Turma atualizada com sucesso", "0", true);
        this.getData();
      } else {
        Message.ExibeMensagem(result, "2", true);
      }
      this.setState({isLoadingBtn: false});
    } catch (e) {
      console.error(e)
    } finally {
      if (this.props.handleShowCsat) this.props.handleShowCsat(csatList.trocaDeTurma)
    }
  }

  SelecioneTurma(novaTurma) {

    var campos = this.state.DadosNovaTurma;
    campos.novaTurma = novaTurma.id;

    this.setState({DadosNovaTurma: campos});
    this.setState({nomeTurma: novaTurma.nome + " - Período: " + novaTurma.periodo});
  }

  render() {
    const {
      isLoading,
      Matricula,
      DadosMatricula,
      Turmas,
      DadosNovaTurma,
      isLoadingBtn,
      permiteMovimentarAvista,
    } = this.state;

    {
      if (!permiteMovimentarAvista) {
        return (
          <div>
            <div className=" form-group d-flex justify-content-center">
              <h3 style={{color: '#0068b2'}}><strong>AVISO</strong></h3>
            </div>
            <div className=" form-group d-flex justify-content-center">
              <h5 style={{color: '#0068b2'}}> O Template selecionado não permite movimentações onde o pagamento é
                Avista.</h5>
            </div>
          </div>
        );
      }
    }

    if (isLoading) {
      return <LoadingAnimation/>;
    } else {
      return (
        <div>
          <div className="step-1">
            <h1 className="modal-title">Aluno(a): {Matricula?.estudante_Name}</h1>
            <h4>Turma atual: <b>{DadosMatricula?.turma_Name}</b>. <br/> Período: <b>{DadosNovaTurma?.periodo}</b>.</h4>
          </div>
          <form onSubmit={(e) => this.Salvar(e)}>
            <div className="row">
              <div className="col-md-6">
                <FormControl
                  fullWidth
                  error={!DadosNovaTurma?.novaTurma}
                  size="small"
                  disabled={isLoadingBtn}
                >
                  <label>Turmas Disponíveis</label>
                  <Select
                    labelId="select-turma"
                    label="Turma"
                    onChange={event => {
                      const turma = Turmas.find(t => t.id === event.target.value);
                      if (turma) this.SelecioneTurma(turma)
                    }}
                  >
                    {(Turmas || []).length === 0 && (
                      <MenuItem>
                        Nenhuma turma disponível
                      </MenuItem>
                    )}
                    {(Turmas || []).map((turma) => (
                      <MenuItem key={turma.id} value={turma.id}>
                        {turma.nome + " - Período: " + turma.periodo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6 mt-3">
                <button
                  type="submit"
                  className="btn btn-primary mt-2"
                  disabled={isLoadingBtn ? "disabled" : ""}>
                  {isLoadingBtn ? "Salvando..." : "Salvar"}
                </button>
              </div>
            </div>

          </form>
        </div>
      );
    }
  }
}

export default withUnidadeMatricula(TrocaDeTurma, "Troca de Turma", csatList.trocaDeTurma);
