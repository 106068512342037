import React, { Component } from "react";
import '../../styles/css/app.css';
import * as _matriculaService from '../../service/matricula-service';
import * as _responsavelService from '../../service/responsavel-service';
import * as _integracaoMatriculaService from "../../service/integracaoMatricula-service";
import * as _anexo from "../../Helpers/anexo"
import * as Message from "../shared/Message";
import * as _movimentacoesService from '../../service/movimentacoes-service.js';
import LoadingAnimation from "../LoadingAnimation/LoadigAnimation";
import csatList from "../../utils/csatList";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import './AtualizarContrato.css'

const tipoDescricaoPagamentoEntrada = [
    "Boleto Bancário",
    "Cartão de Crédito",
    "Débito Automático"
]
const tipoDescricaoPagamentoMensalidade = [
    "Boleto Bancário",
    "Cartão de Crédito Recorrênte",
    "Débito Automático"
]
class AtualizarContrato extends Component {
    static displayName = AtualizarContrato.name;

    constructor(props) {
        super(props);
        this.state = this.getState();
    }
    getState() {
        return {
            responsaveis: [],
            matriculaDetalhada: [],
            movimentacoes: this.props.movimentacoes,
            isLoading: false,
            UrlContratoAtual: "",
            Contratos: [],
            servicoMatriculaList: [],
            params: {
                descricaoPagamentoEntrada: null,
                descricaoPagamentoMensalidade: null,
                nomeServico: null,
                enderecoResponsavel: null,
                nomeAluno: null,
                nomeResponsavelContratual: null
            },
            loadingPreview: false,
            loadingContrato: false,
            criarContrato: false,
            previewContrato: true,
            errors: {},
            errorString: null,
            permiteMovimentarAvista: true
        }
    }

    async componentDidMount() {
        const { movimentacoes } = this.state;
        this.setState({ isLoading: true, errorString: null });
        await Promise.all([this.GetMatricula(), this.GetResponsavelByMatricula(), this.GetMatriculaGeral(), this.VerPreview()])
        if (movimentacoes === true)
            await this.VerificarInfoNecessarias();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.Matricula !== this.props.Matricula) {
            this.setState(this.getState(), () => {
                this.componentDidMount();
            })
        }
    }
    async VerificarInfoNecessarias() {
        const { servicoMatriculaList, responsaveis } = this.state;
        const { Matricula } = this.props;
        var errorString = "";
        if (!servicoMatriculaList) {
            errorString += "(ERP!) Plano de pagamento não localizado, verifique a integração da matricula com o ERP, "
        }
        if (Matricula) {
            if (!Matricula?.estudante_Name) {
                errorString += "(CRM)Nome do aluno não encontrado, "
            }
            if (!Matricula?.servico) {
                errorString += "(CRM)Serviço não encontrado, "
            }
        } else {
            errorString += "(CRM)Matricula não encontrada, "
        }
        if (responsaveis) {
            if (!responsaveis.endereco && !responsaveis.bairro && !responsaveis.estado) {
                errorString += "(CRM)Endereço do responsável não encontrado, "
                this.setState({ errorString: errorString })
            }
            if (!responsaveis.nome) {
                errorString += "(CRM)Nome do responsável não encontrado, "
                this.setState({ errorString: errorString })
            }
        } else {
            errorString += "(CRM)Responsável não encontrado, "
            this.setState({ errorString: errorString })
        }
        if (errorString !== "") {
            this.setState({ errorString: errorString })
        }
    }
    async GetMatricula() {
        var matricula = await _matriculaService.GetMatricula(this.props.Matricula.idMatricula);
        this.setState({ matriculaDetalhada: matricula });

        if (matricula != null && matricula.urlContrato != "" && matricula.urlContrato != null && matricula.urlContrato != undefined) {
            await this.Convert(matricula.urlContrato);
        } else {
            Message.ExibeMensagem("URL do contrato não encontrado", "2", false);
        }
    }
    async GetResponsavelByMatricula() {
        var responsaveis = await _responsavelService.GetResponsavelByMatricula(this.props.Matricula.idMatricula);
        this.setState({ responsaveis: responsaveis });
    }


    async GetMatriculaGeral() {
        try {
            var matriculaGeral = await _matriculaService.GetMatriculaGeral(this.props.Matricula.idMatricula);
            if (matriculaGeral) {
                this.setState({ servicoMatriculaList: matriculaGeral.servicoMatriculaList ?? null });
            }
            if (matriculaGeral.unidade.dataAreaId === null) {
                Message.ExibeMensagem("dataAreaID da unidade está faltando", "2", false);
            }
        } catch (error) {
            this.setState({ previewContrato: false })
            Message.ExibeMensagem("Erro ao buscar matricula", "2", false);
        } if (this.state.movimentacoes === false) {
            this.setState({ isLoading: false });
        }
    }

    async Preview(criarContrato = false) {
        try {
            var params = this.state.params;
            var request = {
                idMatricula: this.props.Matricula.idMatricula,
                criarContrato: criarContrato,
                descricaoPagamentoMensalidade: params.descricaoPagamentoMensalidade ? params.descricaoPagamentoMensalidade : null,
                descricaoPagamentoEntrada: params.descricaoPagamentoEntrada ? params.descricaoPagamentoEntrada : null,
                nomeServico: params.nomeServico ? params.nomeServico : null,
                enderecoResponsavel: params.enderecoResponsavel ? params.enderecoResponsavel : null,
                nomeAluno: params.nomeAluno ? params.nomeAluno : null,
                nomeResponsavelContratual: params.nomeResponsavelContratual ? params.nomeResponsavelContratual : null,
            }
            var result = await _integracaoMatriculaService.Contrato(request);

            if (result && result.statusCode == 200) {
                var contratos = result.value;
                if (contratos && Array.isArray(contratos) && criarContrato == false) {
                    let newParams = contratos[0].itemsContratoEditaveisList;
                    this.setState({ Contratos: contratos, criarContrato: true, previewContrato: true, params: newParams });
                } else {
                    Message.ExibeMensagem("Contrato Gerado Com Sucesso!", "0", false);
                    this.setState({ Contratos: contratos, criarContrato: false, previewContrato: true });
                    if(this.props.movimentacoes === true && this.props.handleShowCsat)
                        this.props.handleShowCsat(csatList.atualizarContrato);
                    await this.GetMatricula();
                }
            } else {
                if (result && result.value) {
                    Message.ExibeMensagem(result.value, "2", false);
                } else {
                    Message.ExibeMensagem("Erro ao buscar ou gerar contrato!", "2", false);
                }
                this.setState({ criarContrato: false, previewContrato: true });
            }
            this.setState({ isLoading: false });
        } catch (e) {
            console.error(e)
        }
    }
    async VerPreview() {
        this.setState({ loadingPreview: true, criarContrato: false, previewContrato: false });
        await this.Preview(false);
        this.setState({ loadingPreview: false });
    }
    async CriarContrato() {
        this.setState({ loadingContrato: true, previewContrato: false, criarContrato: false });
        await this.Preview(true);
        this.setState({ loadingContrato: false });
    }
    onChange(event) {
        var params = this.state.params;
        params[event.target.name] = event.target.value;
        this.setState({ params: params });
    }
    async Convert(url) {
        await _anexo.ConvertUrlToBlob(url).then(response => {

            let preview = "";
            let type = "application/jpg";
            if (url.includes(".pdf")) {
                preview = response.replace("octet-stream", "pdf");
                type = "application/pdf";
            } else {
                preview = response.replace("octet-stream", "jpg");
            }
            this.setState({ UrlContratoAtual: preview });
        })
    }
    render() {
        const {
            UrlContratoAtual,
            isLoading,
            Contratos,
            loadingPreview,
            params,
            criarContrato,
            previewContrato,
            loadingContrato,
            movimentacoes,
            errorString
        } = this.state;
        if (isLoading) {
            return (<LoadingAnimation />);
        } else if (errorString != null) {
            const error = "Verifique as informações necessárias para gerar o contrato: " + errorString + " e tente novamente."
            return <ErrorMessage message={error} />
        }
        else {
            return (
                <div className="row p-b-10">
                    {this.state.movimentacoes === true ? (
                        <div className="row">
                            <div className="col-md-12 ">
                                <button className="mb-4 btn btn-primary" disabled={!criarContrato ? true : false} onClick={async () => { this.CriarContrato() }}>Atualizar Contrato {loadingContrato ? <img width="25" height="25" src="img/loading.gif" /> : ""}</button>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btn btn-primary" disabled={!previewContrato ? true : false} onClick={async () => { this.VerPreview() }}>Preview novo contrato {loadingPreview ? <img width="25" height="25" src="img/loading.gif" /> : ""}</button>
                            </div>
                            <div className="col-md-6">
                                <button className="btn btn-primary" disabled={!criarContrato ? true : false} onClick={async () => { this.CriarContrato() }}>Criar contrato {loadingContrato ? <img width="25" height="25" src="img/loading.gif" /> : ""}</button>
                            </div>
                        </div>
                    )}
                    {movimentacoes === false ? (
                        <div className="seb-secretaria-modal-contrato">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Aluno</label>
                                        <input
                                            className={
                                                "form-control" + `${params.nomeAluno ? " contrato-inputField" : " contrato-inputFieldError"}`
                                            }
                                            defaultValue={params.nomeAluno || ''}
                                            onChange={(e) => this.onChange(e)}
                                            name="nomeAluno"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Responsável</label>
                                        <input
                                            className={
                                                "form-control " +
                                                `${params.nomeResponsavelContratual ? " contrato-inputField" : " contrato-inputFieldError"}`
                                            }
                                            defaultValue={params.nomeResponsavelContratual || ''}
                                            onChange={(e) => this.onChange(e)}
                                            name="nomeResponsavelContratual"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Curso</label>
                                        <input
                                            className={
                                                "form-control " +
                                                `${params.nomeServico ? " contrato-inputField" : " contrato-inputFieldError"}`
                                            }
                                            defaultValue={params.nomeServico || ''}
                                            onChange={(e) => this.onChange(e)}
                                            name="nomeServico"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Endereço do Responsável</label>
                                        <input
                                            className={
                                                "form-control contrato-inputField"
                                            }
                                            defaultValue={params.enderecoResponsavel || ''}
                                            onChange={(e) => this.onChange(e)}
                                            name="enderecoResponsavel"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mb-3 col-md-6">
                                    <div className="form-group">
                                        <label>Metodo Pag. Entrada</label>
                                        <select
                                            className={"form-control contrato-inputField " + `${params.descricaoPagamentoEntrada && params.descricaoPagamentoEntrada !== "" ? " teste" : " default-value"}`}
                                            name="descricaoPagamentoEntrada"
                                            onChange={(e) => this.onChange(e)}
                                            value={params.descricaoPagamentoEntrada || ''}
                                        >
                                            <option className="default-value" value="">Selecione o método da entrada!</option>
                                            {tipoDescricaoPagamentoEntrada.map(metodo => (
                                                <option value={metodo}>{metodo}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3 col-md-6">
                                    <div className="form-group">
                                        <label>Metodo Pag. Parcelamento</label>
                                        <select
                                            className={"form-control contrato-inputField " + `${params.descricaoPagamentoMensalidade && params.descricaoPagamentoMensalidade !== "" ? " teste" : " default-value"}`}
                                            name="descricaoPagamentoMensalidade"
                                            onChange={(e) => this.onChange(e)}
                                            value={params.descricaoPagamentoMensalidade || ''}
                                        >
                                            <option className="default-value" value="">Selecione o método da recorrência!</option>
                                            {tipoDescricaoPagamentoMensalidade.map(metodo => (
                                                <option value={metodo}>{metodo}</option>
                                            ))}
                                            
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ""}
                    <div className="row seb-secretaria-iframe-container">
                        <div className="col-md-6">
                            <h6>Contrato Atual</h6>
                            {
                                (UrlContratoAtual == "" || UrlContratoAtual == undefined) && !isLoading ? "Sem contrato..." : <iframe title="iframe-contrato-atual" type="application/pdf" src={UrlContratoAtual}></iframe>
                            }
                        </div>
                        <div className="col-md-6">
                            {Contratos.length > 0 ? <h6>Novo Contrato</h6> : ""}
                            {
                                Contratos && Contratos.map((contrato, index) => {
                                    const HtmlFromPreviewContrato = "data:text/html;charset=utf-8," + encodeURIComponent(contrato.servicoUnidadeContratoHTML);
                                    return (<iframe title={`iframe-${index}`} src={HtmlFromPreviewContrato}> </iframe>)
                                })
                            }
                        </div>
                    </div>
                </div>

            );
        }
    }

}

export default AtualizarContrato;