import React from 'react';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import AtualizarContrato from "../../components/AtualizarContrato/AtualizarContrato";

function AtualizarContratoPage(props) {
  return (
    <AtualizarContrato
      Matricula={props.Matricula}
      movimentacoes={false}
    />
  );
}

export default withUnidadeMatricula(AtualizarContratoPage, "Atualização de Contrato");