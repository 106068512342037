import React from 'react';
import {Button, Nav, NavDropdown, Spinner} from "react-bootstrap";
import BlockedResourcesTypes from "../../../utils/blockedResourcesTypes";
import BlockedResourcesEntities, {blockedResourcesEntitiesValues} from "../../../utils/blockedResourcesEntities";
import withUnidadeMatricula from "../../../hocs/withUnidadeMatricula";
import * as _blackListService from "../../../service/blacklist-service";
import * as Message from "../../../components/shared/Message";
import {useHistory} from "react-router-dom";
import blockedResourcesSystem, {blockedResourcesSystemNames} from "../../../utils/blockedResourcesSystem";

function Novo(props) {
  const {
    Matricula,
    Unidade,
  } = props;
  const history = useHistory();
  const [data, setData] = React.useState({
    sistemaId: `${process.env.REACT_APP_CLIENT_ID}`,
    action: "",
    type: blockedResourcesEntitiesValues.Matricula,
    mensagemBloqueio: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  
  const handleTypeChange = (type) => {
    let newData = {...data};
    newData.type = type;
    setData(newData)
  }

  const handleSystemChange = (sistemaId) => {
    let newData = {...data};
    newData.sistemaId = sistemaId;
    setData(newData)
  }
  
  const getBlockedResourceValue = () => {
    switch (data.type) {
      case blockedResourcesEntitiesValues.Matricula:
        return Matricula?.codigoMatricula;
      case blockedResourcesEntitiesValues.Unidade:
        return Unidade?.codigoERP;
      default:
        return '';
    }
  }

  const getBlockedResourceLabel = () => {
    switch (data.type) {
      case blockedResourcesEntitiesValues.Matricula:
        return "Matrícula CRM"
      case blockedResourcesEntitiesValues.Unidade:
        return "Unidade CRM"
      default:
        return '';
    }
  }
  
  const handleSaveBlockedResource = async () => {
    if (isLoading) return;
    
    setIsLoading(true);
    
    let newData = {...data};
    
    switch (data.type) {
      case blockedResourcesEntitiesValues.Matricula:
        newData.matriculaId = Matricula?.idMatricula;
        break;
      case blockedResourcesEntitiesValues.Unidade:
        newData.unidadeId = Unidade?.id;
        break;
      default:
        console.error("Tipo de recurso não encontrado")
        break;
    }
    
    newData.action = data.action || BlockedResourcesTypes.filter(t => t.type === data.type)[0].action;
    
    _blackListService.Create(newData)
      .then(() => {
        history.push("/integracao/bloqueio-de-recursos");
      })
      .catch(() => {
        Message.ExibeMensagem("Erro ao criar", "2", true);
      })
  }
  
  return (
    <div>
      <h2 className="tituloCss">Novo Bloqueio de Recurso</h2>
      <div className="panel">
        <div className="panel-body">
          <div className="col mb-2">
            <div className="col-md-6">
              <div className="form-group">
                <label>Sistema</label>
                <Nav className='me-auto'>
                  <NavDropdown
                    className="ddlFundoBrancoSemBorda form-control"
                    value={data.sistemaId || blockedResourcesSystem.SMO}
                    title={blockedResourcesSystemNames[data.sistemaId] || blockedResourcesSystemNames[blockedResourcesSystem.SMO]}
                  >
                    {Object.values(blockedResourcesSystem).map((resource, idx) => (
                      <NavDropdown.Item
                        key={idx}
                        onClick={() => handleSystemChange(resource)}
                        className="cssScroll dropDownItemSemBorda"
                      >
                        {blockedResourcesSystemNames[resource]}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </Nav>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Entidade</label>
                <Nav className='me-auto'>
                  <NavDropdown
                    className="ddlFundoBrancoSemBorda form-control"
                    value={data.type || BlockedResourcesEntities[0].value}
                    title={BlockedResourcesEntities.find(x => x.value === data.type)?.label || BlockedResourcesEntities[0].label}
                  >
                    {BlockedResourcesEntities.map((resource, idx) => (
                      <NavDropdown.Item
                        key={idx}
                        onClick={() => handleTypeChange(resource.value)}
                        className="cssScroll dropDownItemSemBorda"
                      >
                        {resource.label}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </Nav>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>{getBlockedResourceLabel()}</label>
                <input
                  className="form-control"
                  value={getBlockedResourceValue()}
                  disabled
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Mensagem de Aviso</label>
                <input
                  className="form-control"
                  value={data.mensagemBloqueio}
                  onChange={(e) => {
                    setData({
                      ...data,
                      mensagemBloqueio: e.target.value
                    })
                  }}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Tipo de recurso</label>
                <Nav className='me-auto'>
                  <NavDropdown
                    className="ddlFundoBrancoSemBorda form-control"
                    value={data.action || BlockedResourcesTypes.filter(t => t.type === data.type)[0].action}
                    title={data.action || BlockedResourcesTypes.filter(t => t.type === data.type)[0].action}
                  >
                    {BlockedResourcesTypes.filter(t => t.type === data.type && t.system === data.sistemaId).map((resource, idx) => (
                      <NavDropdown.Item
                        key={idx}
                        onClick={() => {
                          setData({
                            ...data,
                            action: resource.action
                          })
                        }}
                        className="cssScroll dropDownItemSemBorda"
                      >
                        {resource.action} - {resource.description}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </Nav>
              </div>
            </div>

            <div className="col-md-1 mb-3">
              <Button
                type="button"
                variant="primary"
                disabled={isLoading}
                style={{marginTop: 15}}
                onClick={handleSaveBlockedResource}
              >
                {isLoading ? (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                  >
                    <span className="visually-hidden">Carregando...</span>
                  </Spinner>
                ) : "Salvar"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withUnidadeMatricula(Novo, 'Novo Bloqueio');