import React from 'react';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';

function SelectionColumn(
  {
    innerRef = null,
    checked = false,
    onChange = () => {},
  }
) {
  return (
    <TableCell padding="checkbox">
      <Checkbox checked={checked} sx={{color: '#FFFFFF'}} onChange={onChange}/>
    </TableCell>
  );
}

export default SelectionColumn;