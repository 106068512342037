import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function buscarCep(cep) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            buscarCep
        },
        error,
        errors
    } = await client.query({
        query: gql`
        query BuscarCep($cep: String) {
          buscarCep(cep: $cep) {
            cep
            logradouro
            complemento
            bairro
            localidade
            uf
            ibge
            gia
            ddd
            siafi
          }
        }
        `,
        variables: {
            cep
        }
    })
    if (error) {
        alert("Erro ao listar matriculas.");
    }

    dispatchGraphError(errors);

    return buscarCep;
}