
export function DownloadArquivoPDF(base64, fileName) {

    if (base64 != undefined && base64 != null) {
        var link = document.createElement('a');
        link.href = 'data:application/pdf;base64,' + base64;
        link.download = fileName + '_' + Date.now() + '.pdf';
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
    }
}

export function ValidaBoleto(boleto) {
    var result = true;

    if (boleto == null || boleto == undefined) {
        result = false;
    }

    if (boleto.boleto == null || boleto.boleto == undefined) {
        result = false;
    }

    return result;
}

export function RetornaMsgBoleto(boleto) {
    var result = "";

    if (boleto != null && boleto != undefined) {

        if (boleto.msgBoletoRegistrado != null && boleto.msgBoletoRegistrado != undefined && boleto.msgBoletoRegistrado != "") {
            result = boleto.msgBoletoRegistrado;
        }
    }

    return result;
}
export function FormatarMoedaToLocale(valor) {
    if (valor != undefined) {
        return valor.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
}