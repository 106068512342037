import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const showSelectMatriculasState = atom<boolean>({
  key: 'showSelectMatriculasState',
  default: true,
  effects: [
    persistAtomEffect
  ]
});
